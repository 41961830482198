import { useParams } from 'react-router-dom';
import { Tabs } from 'element-react';
import { jump } from '../../utils';
import { menus } from '../../utils/constant';
import IndustryApplicationContent from '../../components/IndustryApplicationContent';
import FixedButton from '../../components/FixedButton';

const IndustryApplication = () => {
  const params = useParams();

  return <div className='industryApplication'>
    <Tabs activeName={ params.type } onTabClick={tab => jump(`/industryApplication/${tab.props.name}`)}>
      { menus[3].children.map(item => <Tabs.Pane key={`industryApplication_${item.type}`} label={item.name} name={item.type}>
        <IndustryApplicationContent type={item.type} />
      </Tabs.Pane>) }
    </Tabs>
    
    <FixedButton menus={menus[3].children} active={params.type} />
  </div>
}

export default IndustryApplication;