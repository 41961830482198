import { useParams } from 'react-router-dom';
import { Tabs } from 'element-react';
import { jump } from '../../utils';
import { menus } from '../../utils/constant';
import FixedButton from '../../components/FixedButton';
import source1 from '../../styles/imgs/technology/1.source.png';
import source2 from '../../styles/imgs/technology/2.source.png';
import source3 from '../../styles/imgs/technology/3.source.png';
import bigdata1 from '../../styles/imgs/technology/1.bigdata.png';
import bigdata2 from '../../styles/imgs/technology/2.bigdata.png';
import bigdata3 from '../../styles/imgs/technology/3.bigdata.png';
import communication1 from '../../styles/imgs/technology/1.communication.png';
import communication2 from '../../styles/imgs/technology/2.communication.png';
import communication3 from '../../styles/imgs/technology/3.communication.png';
import './index.scss';

const Technology = () => {
  const params = useParams();

  return <div className='technology'>
    <Tabs activeName={ params.type } onTabClick={tab => jump(`/technology/${tab.props.name}`)}>
      <Tabs.Pane label="开源/国产化" name="source">
        <div className='wrap'>
          <div className='title'>开源/国产化</div>
          <div className='content'>
            <div className='text'>
              <div className='title'>开放、共享，软件开源化</div>
              <div className='describe'>
                <div className='title' style={{color: '#F9953F'}}>免费 · 开源 · 自主 · 可控</div>
                先进开源技术为本 ，极致性价比模式为刚，提供专家级的企业信息化服务。
                <div className='title' style={{color: '#797FF4'}}>节约时间</div>
                节约时间是对于自主拥有技术团队的企业来说，在网站需要完善、改版的时候，使用开<br />
                源商城系统只需在原程序上进行修改即可实现。
                <div className='title' style={{color: '#54D1C6'}}>个性化</div>
                竞争的加大、用户的激增，企业、用户对于商城界面以及功能都拥有了更多的需求，对<br />
                于不同功能的实现，开源可以使程序员在代码上基础上进行二次开发，表现出个性化的<br />
                新功能。
              </div>
            </div>
            <img src={source1} alt="img" />
          </div>
          <div className='content' style={{backgroundColor: '#F5F5F5'}}>
            <img src={source2} alt="img" />
            <div className='text'>
              <div className='title'>兼容鸿蒙</div>
              <div className='describe'>
                鸿蒙是分布式架构首次用于终端OS，实现跨终端无缝协同体验。鸿蒙为开源，可用于<br />
                大屏、PC、汽车等各种不同的设备上。鸿蒙还可以随时用在手机上，鸿蒙可兼容安卓<br />
                系统。鸿蒙还通过统一IDE支撑一次开发，多端部署，实现跨终端生态共享。<br />
                <br />
                推动国产操作系统的创新应用与发展，积极参与鸿蒙系统的生态建设，我们系统已率先<br />
                实现基于鸿蒙系统的APP端支持，可以鸿蒙系统下查看、浏览、操作平台系统。
              </div>
            </div>
          </div>
          <div className='content'>
            <div className='text'>
              <div className='title'>多种采集方案/兼容架构</div>
              <div className='describe'>
              <div className='title'>客户端（前端）</div>
              支持 iOS、安卓、Web/H5、微信小程序，主要用于分析 UV、PV、点击量等基本指标。
              <div className='title'>服务器日志</div>
              采集后端业务服务器打印的日志。更强的采集能力，更好的支撑精细化分析场景。
              <div className='title'>业务数据库</div>
              采集存储在各个关系型数据库中。让用户行为数据与业务数据结合，满足复杂分析场景。
              <div className='title'>历史数据</div>
              支持历史数据一次性导入，激活过往数据，挖掘潜在价值。
              </div>
            </div>
            <img src={source3} alt="img" />
          </div>
        </div>
      </Tabs.Pane>
      <Tabs.Pane label="大数据分析" name="bigdata">
        <div className='wrap'>
          <div className='title'>大数据分析</div>
          <div className='content'>
            <div className='text'>
              <div className='title'>数据中台</div>
              <div className='describe'>
                提供可视化界面，让市场、产品、运营等业务人员更高效地进行深度数据分析。<br />
                开放查询接口，灵活扩展已有的分析功能，支持与其它内部业务系统进行无缝对接。<br />
                提供了基于全量数据的离线计算能力，用于文本挖掘、搜索优化、精准广告、深度分析<br />
                等应用。<br />
                提供了实时订阅数据的能力，支持分布式的流式计算，用于实现个性化推荐、精准广告<br />
                等应用。<br />
                完全开放的数据接入协议，可以扩展实现接入任意数据源的数据。  
              </div>
            </div>
            <img src={bigdata1} alt="img" />
          </div>
          <div className='content' style={{backgroundColor: '#F5F5F5'}}>
            <img src={bigdata2} alt="img" />
            <div className='text'>
              <div className='title'>数据处理、分析</div>
              <div className='describe'>
                数据处理、分析数据整合、流转与管理。<br />
                看板共享功能及健全的权限管理体系，让数据在企业职员之间高效流转；整合多平台用<br />
                户行为数据，支持原始数据导出，让数据管理更便捷。<br />
                <br />
                一站式大数据分析平台。<br />
                企业可以在一个统一的平台.上完成全流程数据分析任务，极大降低了实施、集成、培<br />
                训的成本。致力于元进的数据技术、优秀的可视化能力、酷炫的大屏展示效果。
              </div>
            </div>
          </div>
          <div className='content'>
            <div className='text'>
              <div className='title'>多维数据分析</div>
              <div className='describe'>
                追踪用户行为，分析核心流程转化，快速验证改版方案，让新用户更多留存，让老用户<br />
                更多消费。<br />
                提升用户转化<br />
                漏斗分析核心转化流程（如支付、注册、开户等），洞察流失问题，提升用户转化。<br />
                提高用户留存<br />
                数据验证“最受欢迎”功能，迭代 A/B 测试，评估效果，强化特点，提升活跃和存。<br />
                改善用户体验<br />
                追踪用户行为路径，合理分发站内流量，微调产品布局和交互，提升用户体验。
              </div>
            </div>
            <img src={bigdata3} alt="img" />
          </div>
        </div>
      </Tabs.Pane>
      <Tabs.Pane label="数据通信" name="communication">
        <div className='wrap'>
          <div className='title'>数据通信</div>
          <div className='content'>
            <div className='text'>
              <div className='title'>物联网通讯</div>
              <div className='describe'>
                <div className='title' style={{color: '#F9953F'}}>一键全自动部署方案</div>
                打造“一键”部署的标准方案，高度兼容，实现85%客户小时内完成部署，大大提升效<br />
                率，解放人力。
                <div className='title' style={{color: '#797FF4'}}>海量数据处理能力</div>
                面对日益增长的海量数据，保证实时查询性能的前提下，对企业硬件配置标准和数量<br />
                (CPU、内存、硬盘、服务器数量)要求低，成本更低。
              </div>
            </div>
            <img src={communication1} alt="img" />
          </div>
          <div className='content' style={{backgroundColor: '#F5F5F5'}}>
            <img src={communication2} alt="img" />
            <div className='text'>
              <div className='title'>采集稳定性、专利</div>
              <div className='describe'>
                <div className='title' style={{color: '#54D1C6'}}>安全、可靠、稳定</div>
                整体方案中包括完整的运维系统，深度打磨的自动化运维工具，报警及时，准确定位异<br />
                常，助力客户在日常工作中高效自主的运维。
                <div className='title' style={{color: '#FA7888'}}>安全、可靠、稳定</div>
                私有化部署方案规避了公网与内网在数据集成安全性和稳定性上的缺陷，开放的API接<br />
                口满足灵活的二次开发，为多系统、多维度的交叉分析需求提供有力保障。
              </div>
            </div>
          </div>
          <div className='content' style={{backgroundColor: '#F5F5F5'}}>
            <div className='text'>
              <div className='title'>安全性、高效性</div>
              <div className='describe'>
                <div className='title' style={{color: '#3AC3FD'}}>确保数据安全</div>
                无论是用户数据还是业务数据，都是企业立命之本。私有化方案配合完全开源的SDK<br />
                ，确保数据从采集到应用完全透明、安全。
                <div className='title' style={{color: '#FAB910'}}>丰富的部署经验</div>
                拓远从创立第一天开始就为客户提供了完整的私有化部署方案，并据此为数百家付费企<br />
                业完成了高质量的交付与实施，持续运行稳定、流畅。
              </div>
            </div>
            <img src={communication3} alt="img" />
          </div>
        </div>
      </Tabs.Pane>
    </Tabs>
    
    <FixedButton menus={menus[2].children} active={params.type} />
  </div>
}

export default Technology;