import { useRef, useMemo } from 'react';
import { datas } from '../../utils/industryApplication';
import './index.scss';

const IndustryApplicationContent = ({ type }) => {
  const data = useRef(datas[type]);
  // const [page, setPage] = useState(0);

  // useEffect(() => {
  //   if(document.getElementsByTagName('html')[0].scrollTop > 480) document.getElementsByTagName('html')[0].scrollTo({ top: 480, behavior: "smooth" });
  // }, [page]);

  // const handleGoDown = (num) => {
  //   const newPage = page + num;
  //   if(newPage < 0 || newPage  > data.current.list.length-1) return;
  //   setPage(newPage);
  // }

  const contentDom = useMemo(() => {
    if(!data.current.list) return '';
    return data.current.list.map((item, i) => {
      return item.img ? <div
        key={`${type}_IndustryApplicationContentItem_${i}`}
        className='industryApplicationContentItem'
      >
        <img src={item.img} alt='img' />
        <div className='text'>
          <div className='title'>{ item.title }</div>
          <div className='describe'>{ item.describe }</div>
        </div>
      </div> : 
      <div style={{ height: '380px' }}></div>
    });
  }, [type]);
  // const footer = useMemo(() => {
  //   return data.current.list.map((item, i) => <button
  //     key={`industryApplicationContentpageButton_${i}`}
  //     onClick={() => setPage(i)}
  //     className={`pagination_button ${i === page && 'pageSelected'}`}
  //   >
  //     { i+1 }
  //   </button>);
  // }, [page]);
  return <div className="wrap industryApplicationContent">
    <div className="title">{ data.current?.title }</div>
    <div className='content'>
      { contentDom }
    </div>
    {/* <div className='pageSet'>
      <span className='pagination_number'>共 { data.current?.count } 条</span>
      <button className="pagination_button" onClick={() => handleGoDown(-1)}> &lt; </button>
      { footer }
      <button className="pagination_button" onClick={() => handleGoDown(1)}> &gt; </button>
    </div> */}
  </div>
}

export default IndustryApplicationContent;