import { useMemo } from 'react';
import { useRef } from 'react';
import { Carousel } from 'element-react';
import { introduced, introducedImgs, brightSpot, architecture, show } from '../../utils/productCenterOther';
import government1 from '../../styles/imgs/productCenter/government/government1.png';
import government2 from '../../styles/imgs/productCenter/government/government2.png';
import government3 from '../../styles/imgs/productCenter/government/government3.png';
import government4 from '../../styles/imgs/productCenter/government/government4.png';
import './index.scss';

const ProductCenterContent = ({ type }) => {
  const introducedList = useRef(type ? introduced[type] : []);
  const brightSpotList = useRef(type ? brightSpot[type] : []);
  const architectureList = useRef(type ? architecture[type] : []);
  const textCarousel = useRef();
  const { current: showList } = useRef(type ? show[type] : []);

  const otherContent = useMemo(() => {
    if(!brightSpotList.current || !architectureList.current || !showList) return '';
    return <>
      <div className='wrap brightSpot'>
        <div className='title'>功能亮点</div>
        <div className='content'>
          {
            brightSpotList.current.map((item, i) => <div
              key={`${type}_brightSpotItem_${i}`}
              className='brightSpotItem'
            >
            <img src={item.icon} alt='img' />
              <div className='title'>{ item.title }</div>
              <div className='content'> { item.describe }</div>
            </div>)
          }
        </div>
      </div>
      {
        type !== 'carbon' &&
        <div className='wrap architecture'>
          <div className='title'>产品架构</div>
          <div className='content'>
            {
              architectureList.current.map((item, i) => <img
                key={`${type}_architectureItem_${i}`}
                src={item}
                alt='img'
              />)
            }
          </div>
        </div>
      }
      <div className='wrap show'>
        <div className='title'>系统展示</div>
        <div className='content' style={{ display: 'block' }}>
          {
            // type === 'carbon' && <div className='text'>
            //   <div className='title'>数据方舟云平台解决方案</div>
            //   <div className='describe'>
            //     有效控制排放量，提升能源使用效率，降低能耗，以实现“碳中和”目标，能<br />
            //     源生产、传输、存储和使用的全生命周期中，通过云平台进行智慧管控，帮<br />
            //     助客户降低转型成本，从而实现绿色、可靠高效的能源管理目标。<br />
            //     我们提供从专业的顶层设计咨询业务，软件、硬件方案部著，以及项目全生命<br />
            //     周期的服务，帮助客户打通能源管理到端的全价值链条，从而更好地满足能源<br />
            //     管理和生产效率的双向需求。帮助企业实现可持续、高效、韧性、灵活加
            //   </div>
            // </div>
          }
          <Carousel
            type="card"
            arrow='never'
            height="320px"
            interval="4000"
            indicatorPosition='none'
            onChange={i => textCarousel.current && textCarousel.current.setActiveItem(i)}
          >
            {
              showList.map((item, index) => {
                return (
                  <Carousel.Item key={index}>
                    <img src={item.img} alt='img' width='100%' />
                  </Carousel.Item>
                )
              })
            }
          </Carousel>
          <Carousel
            arrow='never'
            height="220px"
            autoplay={false}
            ref={textCarousel}
            indicatorPosition='none'
          >
            {
              showList.map((item, index) => {
                return (
                  <Carousel.Item key={index}>
                    <div className='showText title'>{ item.title }</div>
                    <div className='showText text'>{ item.text }</div>
                  </Carousel.Item>
                )
              })
            }
          </Carousel>
        </div>
      </div>
    </>
  }, [type, showList]);

  const governmentContent = useMemo(() => {
    return <>
      <div className='wrap government'>
        <div className='title'>平台建设</div>
        <div className='content'>
          <div className='text'>
            <div className='title'>韶关节能监察中心|政府能源在线监管平台</div>
            <div className='describe'>
              拓远为韶关节能监察中心建造的韶关市重点用能单位能源信息管理系统主要为<br />
              政府能源在线监管平台及企业能源上报平台，主要功能为综合能源消耗量分析<br />
              、企业经济指标分析、企业单位产品能耗分析、企业节能完成进度企业、主<br />
              要能耗设备节能等等。
            </div>
          </div>
          <img src={government1} alt='img' />
        </div>
        <div className='content'>
          <img src={government2} alt='img' />
          <div className='text'>
            <div className='title'>东莞市重点用能企业能源监管平台</div>
            <div className='describe'>
            针对东莞市重点用能单位的现状，为东莞市经济和信息化局建立了能源数据在<br />
            线监管及有源上报平台。平台包括全市重点能耗监测、能耗分析、能耗利用状<br />
            况报表及设备管理等功能。<br />
            国家能耗在线监测调研组对东莞市能源在线监测系统及“十二五”节能规划<br />
            项目的落实给予了充分的肯定。目前东莞能源管理中心已被列为国家能源在线监<br />
            测试点。
            </div>
          </div>
        </div>
        <div className='content'>
          <div className='text'>
            <div className='title'>江门市重点用能单位能源信息管理系统</div>
            <div className='describe'>
            拓远为江门经信局建造的江门市重点用能单位能源信息管理系统主要为政府<br />
            能源在线监管平台及企业能源上报平台，主要功能为综合能源消耗量分析、企<br />
            业经济指标分析、企业单位产品能耗分析、企业节能完成进度企业、主要能耗<br />
            设备节能等等。
            </div>
          </div>
          <img src={government3} alt='img' />
        </div>
      </div>
      <div className='wrap government'>
        <div className='title'>校园能源/节水管理</div>
        <div className='content'>
          <div className='text'>
            <div className='describe'>
              节约型校园的建设和运营管理，应在校园设施的全寿命周期内、在各个环节中<br />
              统筹考虑节能、节水、节地、节材和环境保护，满足校园功能之间的辩证关<br />
              系，推进先进技术、科学管理的示范建设，注重社会效益、经济效<br />
              益和环境效益的统一。<br />
              拓远校园能源管理系统将帮助学校进行高效的能源管理，通过对水、电、气等<br />
              各种能源进行即时的数据采集，对学校能源利用效率、消耗水平、能源经济与<br />
              环境效果进行审计、监测、诊断和评价。能源及资源使用方的管理。通过政策<br />
              制度引导和激励用户节约、合理使用能源及资源。
            </div>
          </div>
          <img src={government4} alt='img' />
        </div>
      </div>
    </>
  }, [])

  return <div className="ProductCenterOther">
    <div className='wrap introduced'>
      <div className='title'>系统介绍</div>
      <div className='content'>
        {
          introducedList.current.map((item, i) => <div
            key={`${type}_introducedItem_${i}`}
            className='introducedItem'
          >
            <div className='title'>{ item.title }</div>
            <div className='content'>
              <img src={introducedImgs[i]} alt='img' />
              <div className='text'>
                { item.describe }
              </div>
            </div>
          </div>)
        }
      </div>
    </div>
    { type === 'government' ? governmentContent : otherContent }
  </div>
}

export default ProductCenterContent;