const CompanyInfo = () => {
  return <>
    地址：深圳市南山区科技园科技中二路深圳软件园二期11栋305<br />
    电话：+86755-8665 5360<br />
    传真：+86755-8662 6516<br />
    联系人：季小姐 +86 13632680973<br />
    E-mail：fengyy@esdenergy.com<br />
    热线：4000-866-365
  </>
}

export default CompanyInfo;