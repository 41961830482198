import { useRef, useEffect, useState } from 'react';
import { Carousel, Dropdown } from 'element-react';
import { jump, history } from '../../utils';
import { benners, menus } from '../../utils/constant';
import nav_logo_black from '../../styles/imgs/banner/nav_logo_black.png';
import './index.scss';

const Header = () => {

  const [path, setPath] = useState(history.location.pathname);
  const [banners, setBanners] = useState([]);
  const navRef = useRef();
  const bannerRef = useRef();

  useEffect(() => {
    history.listen(({ location: { pathname } }) => {
      if(!bannerRef.current) return;
      document.getElementsByTagName('html')[0].scrollTop = 0;
      bannerRef.current.pauseTimer();
      if(!['/', '/contact'].includes(pathname)) {
        // eslint-disable-next-line no-unused-vars
        const [_, mode] = pathname.split('/');
        const module = menus.find(m => m.modulePath === mode);
        const index = module.children.findIndex(m => m.url === pathname);
        bannerRef.current.setActiveItem(index);
      }
      bannerRef.current.startTimer();
      setPath(pathname);
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(path) {
      const type = path.split('/')[1];
      setBanners(!type ? benners.home : benners[type]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  return <div className='header'>
    <div className='navWrap'>
      <div ref={navRef} className='nav'>
        <img className='logo' src={nav_logo_black} alt='logo' />
        <div className='menu'>
          {
            menus.map((item, i) => {
              return item.children?.length ?
              <Dropdown
                key={`headerNavMenuItem_${item.url}`}
                onCommand={e => jump(e)}
                menu={(
                  <Dropdown.Menu splitButton={true} >
                    { item.children.map((item, x) => <Dropdown.Item
                      key={`headerNavMenuItem_${i}_${x}`}
                      command={item.url}
                    >
                      <div className={`${path === item.url && 'menuItemSelected'}`} style={{padding: '3px 0'}}>{ item.name }</div>
                    </Dropdown.Item>) }
                  </Dropdown.Menu>
                )}
              >
                <div className={`menuItem ${path?.includes(item.modulePath) && 'menuItemSelected'}`} onClick={() => jump(item.url)}>{ item.name }</div>
              </Dropdown> :
              <div
                key={`headerNavMenuItem_${item.url}`}
                className={`menuItem${path === item.url ? ' menuItemSelected' : ''}`}
                onClick={() => jump(item.url)}
              >
                { item.name }
              </div>
            })
          }
        </div>
      </div>
    </div>
    <div style={{ height: 72 }} />
    <Carousel ref={bannerRef} height='480px' interval={5000}>
      {
        banners.map((item, index) => {
          return (
            <Carousel.Item
              key={`header_bennerImgs_${index}`}
              style={{ position: 'relative' }}
              className={`carouselItem ${path ==='/' && 'homeCarouselItem'}`}
            >
              <img src={item.img} alt='图片' />
              <div className='content'>
                <div className='title'>{ item.title }</div>
                <div className='text'>{ item.content }</div>
                { item.title && <button onClick={() => jump(item.url)}>进入平台</button> }
              </div>
            </Carousel.Item>
          )
        })
      }
    </Carousel>
  </div>
}

export default Header;