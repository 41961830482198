import { useParams } from 'react-router-dom';
import { Tabs } from 'element-react';
import { jump } from '../../utils';
import { menus } from '../../utils/constant';
import ProductCenterContent from '../../components/ProductCenterContent';
import FixedButton from '../../components/FixedButton';

const ProductCenter = () => {
  const params = useParams();

  return <div className='productCenter'>
    <Tabs activeName={ params.type } onTabClick={tab => jump(`/productCenter/${tab.props.name}`)}>
      { menus[1].children.map(item => <Tabs.Pane key={`productCenter_${item.type}`} label={item.name} name={item.type}>
        <ProductCenterContent type={item.type} />
      </Tabs.Pane>) }
    </Tabs>

    <FixedButton menus={menus[1].children} active={params.type} />
  </div>
}

export default ProductCenter;