import { useState, useRef, useReducer, useMemo } from 'react';
import { Map, Marker, NavigationControl, Label, MapApiLoaderHOC } from 'react-bmapgl';
import { Input, Button, Icon } from 'element-react';
import { courseList, sideNavMenus } from '../../utils/constant';
import { baiduAk } from '../../axios/config';
import CompanyInfo from '../../components/CompanyInfo';
// import image1 from '../../styles/imgs/contact/1.image_@1x.png';
import image2 from '../../styles/imgs/contact/2.image_@1x.png';
import image3 from '../../styles/imgs/contact/3.image_@1x.png';
import SideNav from '../../components/SideNav';
import './index.scss';

const Contact = () => {
  const courseListRef = useRef();
  const { current: initVal } = useRef(-(200 * (courseList.length - 5) - 160));
  const reducer = (state, action) => {
    let temp;
    switch(action) {
      case '+':
        temp = state + 480;
        return temp >= 30 ? 30 : temp;
      case '-':
        temp = state - 480;
        return temp <= initVal ? initVal : temp;
      default:
    }
  }
  const [courseTranslateX, dispatch] = useReducer(reducer, initVal);
  const arr = useRef(["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z",
  "a","b","c","d","e","f","g","h","i","j","k","l","m","n","o","p","q","r","s","t","u","v","w","x","y","z",
  0,1,2,3,4,5,6,7,8,9]);
  const [code, setCode] = useState(
    arr.current[parseInt(Math.random() * (arr.current.length - 1))] + 
    arr.current[parseInt(Math.random() * (arr.current.length - 1))] + 
    arr.current[parseInt(Math.random() * (arr.current.length - 1))] + 
    arr.current[parseInt(Math.random() * (arr.current.length - 1))]
  );
  const { current: mapPosition } = useRef({lng: 113.944305, lat: 22.551100});

  const getCode = () => {
    setCode(
      arr.current[parseInt(Math.random() * (arr.current.length - 1))] + 
      arr.current[parseInt(Math.random() * (arr.current.length - 1))] + 
      arr.current[parseInt(Math.random() * (arr.current.length - 1))] + 
      arr.current[parseInt(Math.random() * (arr.current.length - 1))]
    );
  }

  const goToBaiduMap = () => {
    window.open('https://map.baidu.com/poi/%E6%B7%B1%E5%9C%B3%E5%B8%82%E6%8B%93%E8%BF%9C%E8%83%BD%E6%BA%90%E7%A7%91%E6%8A%80%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8/@12684210.755,2561330.59,19.09z?uid=1f8343a2b66dabfd3b221c28&ugc_type=3&ugc_ver=1&device_ratio=1&compat=1&pcevaname=pc4.1&querytype=detailConInfo&da_src=shareurl')
  }

  const yearList = useMemo(() => {
    const activeYear = new Date().getFullYear();
    return courseList.map(({ year, event }, i) => <div
      key={`courseListItem_${year}`}
      className={`listItem${activeYear === year ? ' active' : ''}`}
      >
      { year }
      <div className='circle'>
        <div></div>
      </div>
      <div className='event'>{ event }</div>
    </div>)
  }, []);
  return <div className='contact'>
    <div className='wrap company'>
      <div className='title'>公司简介</div>
      <div className='content'>
        <div className='text'>
          公司于2008年成立，专注从事企业、建筑、政府能源管理服务平台建设的科技企<br />
          业。拥有12年能源管理系统平台行业经验，30多个行业领域，1000余家企业建筑，<br />
          其中不乏世界500强知名企业。多个政府能源平台：深圳、惠州、江门、韶关、东<br />
          莞经信平台，南山区能源综合服务平台。不仅能大幅度降低企业的使用及管理成<br />
          本，并大幅度提高数据质量，实现移动管理。
          {/* <div className='title'>公司资质</div> */}
        </div>
        <img src={image3} alt='img' />
      </div>
    </div>
    <div className='wrap course'>
      <div className='title'>发展历程</div>
      <button
        className='arrow arrowLeft'
        onClick={() => dispatch('+')}
        style={{ display: courseTranslateX >= 0 ? 'none' : 'block'  }}
      >
        <Icon name='arrow-left' />
      </button>
      <div className='listWrap'>
        <div ref={courseListRef} className='list' style={{ transform: `translateX(${courseTranslateX}px)` }}>
          {yearList}
        </div>
      </div>
      <button
        className='arrow arrowRight'
        onClick={() => dispatch('-')}
        style={{ display: courseTranslateX <= initVal ? 'none' : 'block'  }}
      >
        <Icon name='arrow-right' />
      </button>
    </div>
    <div className='wrap company'>
      <div className='title'>公司展望</div>
      <div className='content'>
        <img src={image2} alt='img' />
        <div className='text'>
          公司于2008年成立，专注从事企业、建筑、政府能源管理服务平台建设的科技企<br />
          业。拥有12年能源管理系统平台行业经验，30多个行业领域，1000余家企业建<br />
          筑，其中不乏世界500强知名企业。多个政府能源平台：深圳、惠州、江门、韶<br />
          关、东莞经信平台，南山区能源综合服务平台。不仅能大幅度降低企业的使用及<br />
          管理成本，并大幅度提高数据质量，实现移动管理。
        </div>
      </div>
    </div>
    <div className='wrap contactUs'>
      <div className='title'>联系我们</div>
      <div className='content'>
        <div className='map'>
          <Map center={mapPosition} zoom="18" minZoom="11" enableScrollWheelZoom>
            <Marker
              position={mapPosition}
              onClick={goToBaiduMap}
            />
            <NavigationControl /> 
            <Label
              text="深圳市拓远能源科技有限公司"
              style={{
                border: 0,
                color: '#fff',
                padding: '10px',
                backgroundColor: '#062345B3',
                transform: 'translate(-50%, -100%)'
              }}
              position={mapPosition}
              onClick={goToBaiduMap}
            />
          </Map>
        </div>
        <div className='text'>
          <h1 className='title'>深圳市拓远能源科技有限公司</h1>
          <div className='describe'>
            <CompanyInfo />
          </div>
        </div>
      </div>
    </div>
    <div className='messageWrap'>
      <div className='left'>
        <Input placeholder="留言标题" />
        <Input placeholder="联系人" />
        <Input placeholder="邮箱" />
        <div className='verification'>
          <Input placeholder="验证码" maxLength={4} />
          <div className='code' onClick={getCode}>
            { code }
          </div>
        </div>
      </div>
      <div className='right'>
        <Input type="textarea" placeholder="留言信息" />
        <Button type='primary'>提交</Button>
      </div>
    </div>

    <SideNav menus={sideNavMenus.contact} hideSection={[830, 1200]} />
  </div>
}

export default MapApiLoaderHOC({ak: baiduAk})(Contact);