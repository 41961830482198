import { jump } from '../../utils';
import { menus } from '../../utils/constant';
import CompanyInfo from '../../components/CompanyInfo';
import slogon from '../../styles/imgs/banner/slogon.jpg';
import header_nav_logo from '../../styles/imgs/banner/header_nav_logo.png';
import './index.scss';

const Footer = () => {
  return <div className="footer">
    <div className='content'>
      <div className='footerLeft'>
        <div className='logoWrap'>
          <img src={header_nav_logo} alt='img' />
          <div className='text'>
            <span>数</span>
            <span>聚</span>
            <span>提</span>
            <span>升</span>
            <span>效</span>
            <span>率</span>
            <span>！</span>
          </div>
        </div>
        <div className='menuList'>
          { menus.map(item => <dl key={`footerMenuItem_${item.url}`}>
            <dt onClick={() => jump(item.url)}>{ item.name }</dt>
            { item.children?.map(item => <dd key={`footerMenuItem_${item.url}`} onClick={() => jump(item.url)}>{ item.name }</dd>) }
          </dl>) }
        </div>
      </div>
      <div className='footerRight'>
        <div className='imgWrap'>
          <img src={slogon} alt='img' />
          <div>关于我们</div>
        </div>
        <div className='text'>
          <div className='title'>深圳市拓远能源科技有限公司</div>
          <div className='describe'>
            <CompanyInfo />
          </div>
        </div>
      </div>
    </div>
    <div className='copyright'>
      <span>24H Hotline: 4000 866 365</span>
      <span>Copyright © 2011 | 拓远能源. 粤ICP备08127172号</span>  
    </div>
  </div>
}

export default Footer;