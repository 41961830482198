import { Routes, Route, unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import { history } from "./utils";
import Home from './pages/Home';
import ProductCenter from './pages/ProductCenter';
import Technology from './pages/Technology';
import IndustryApplication from './pages/IndustryApplication';
import Contact from './pages/Contact';

const RouterDetail = (
  <Routes>
    <Route path="productCenter/:type" element={<ProductCenter />} />
    <Route path="technology/:type" element={<Technology />} />
    <Route path="industryApplication/:type" element={<IndustryApplication />} />
    <Route path="contact" element={<Contact />} />
    <Route path="contact/:type" element={<Contact />} />
    <Route path="*" element={<Home />} />
  </Routes>
);

// eslint-disable-next-line react-hooks/exhaustive-deps, import/no-anonymous-default-export
export default () => <HistoryRouter history={history} basename='/'>{RouterDetail}</HistoryRouter>;