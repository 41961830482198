import Header from './components/Header';
import Footer from './components/Footer';
import Routes from './routes';
import './App.scss';

function App() {

  return (
    <div className="App">
      <Header />
      <Routes />
      <Footer />
    </div>
  );
}

export default App;
