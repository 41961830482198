import { jump } from '../../utils';
import { homeProductCenter, homeCoreTechnology, homeIndustryApplication, sideNavMenus } from '../../utils/constant';
import coreTechnology_back from '../../styles/imgs/home/13.coreTechnology_back.png';
import SideNav from '../../components/SideNav';
import './index.scss';

const Home = () => {

  return <div className='home'>
    <h1 style={{ position: 'absolute', top: '-1000px' }}>拓远数据</h1>
    <div className='wrap productCenter'>
      <div className='title'>产品中心</div>
      <div className='describe'>提供行业数据产品和服务，全面满足您的业务场景和数据需求</div>
      <div className='content'>
        { homeProductCenter.map((item, i) => <div key={`productCenterItem_${i}`} style={{ overflow: 'hidden' }}>
          <div
            className='productCenterItem'
            style={{ background: `url(${item.backImg}) no-repeat` }}
          >
            <div className='content'>
              <img src={ item.icon } alt='img' />
              <div className='title'>{ item.name }</div>
              <div className='describe'>{ item.describe }</div>
              <button onClick={() => jump(item.url)}>查看详情</button>
            </div>
          </div>
        </div>) }
      </div>
    </div>
    <div className='wrap coreTechnology'>
      <div className='title'>技术核心</div>
      <div className='describe'>以卓越的数据技术为客户创造价值。</div>
      <img src={coreTechnology_back} alt='img' />
      <div className='content'>
        { homeCoreTechnology.map((item, i) => <div
            className='coreTechnologyItem'
            key={`coreTechnologyItem_${i}`}
          >
            <img src={ item.icon } alt='img' />
            <div className='title'>{ item.name }</div>
            { item.describe }
            <button onClick={() => jump(item.url)}>查看详情</button>
          </div>) }
      </div>
    </div>
    {/* <div className='wrap functionCore'>
      <div className='title'>功能核心</div>
      <div className='describe'>用性能卓越的产品实现客户成功。</div>
      <Carousel type="card" interval={5000}>
        {
          homeFunctionCore.map((item, i) => <Carousel.Item key={`functionCoreItem_${i}`}>
            <img src={ item.img } alt='img' />
          </Carousel.Item>)
        }
      </Carousel>
      <div className='functionCoreBottomTitle'>数据方舟平台及看板</div>
      <div className='functionCoreBottomText'>通过数据总览分析</div>
    </div> */}
    <div className='wrap industryApplication'>
      <div className='title'>行业应用</div>
      <div className='describe'>为各种规模的企业提供灵活易用的全业务链的大数据分析解决方案。</div>
      <div className='content'>
        {
          homeIndustryApplication.map((item, i) => <div
            key={`industryApplicationItem_${i}`}
            className='industryApplicationItem'
            onClick={() => jump(item.url)}
            style={{ background: `url(${item.backImg}) no-repeat` }}
          >
            <img src={ item.icon } alt='img' />
            <div className='text'>
              <div className='title'>{ item.name }</div>
              <div className='describe'>{ item.describe }</div>
            </div>
          </div>)
        }
      </div>
    </div>
    <SideNav menus={sideNavMenus.home} />
  </div>
}

export default Home;