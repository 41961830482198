import industrial1 from '../styles/imgs/industryApplication/industrial/1.industrial.png';
import industrial2 from '../styles/imgs/industryApplication/industrial/2.industrial.png';
import industrial3 from '../styles/imgs/industryApplication/industrial/3.industrial.png';
import industrial4 from '../styles/imgs/industryApplication/industrial/4.industrial.png';
import industrial5 from '../styles/imgs/industryApplication/industrial/5.industrial.png';
import industrial6 from '../styles/imgs/industryApplication/industrial/6.industrial.png';
import industrial7 from '../styles/imgs/industryApplication/industrial/7.industrial.jpg';
import industrial8 from '../styles/imgs/industryApplication/industrial/8.industrial.jpg';
import industrial9 from '../styles/imgs/industryApplication/industrial/9.industrial.jpg';
import industrial10 from '../styles/imgs/industryApplication/industrial/10.industrial.jpg';
import industrial11 from '../styles/imgs/industryApplication/industrial/11.industrial.jpg';
import industrial12 from '../styles/imgs/industryApplication/industrial/12.industrial.jpg';
import industrial13 from '../styles/imgs/industryApplication/industrial/13.industrial.jpg';
import industrial14 from '../styles/imgs/industryApplication/industrial/14.industrial.jpg';
import industrial15 from '../styles/imgs/industryApplication/industrial/15.industrial.jpg';
import industrial16 from '../styles/imgs/industryApplication/industrial/16.industrial.jpg';
import industrial17 from '../styles/imgs/industryApplication/industrial/17.industrial.jpg';
import industrial18 from '../styles/imgs/industryApplication/industrial/18.industrial.jpg';
import industrial19 from '../styles/imgs/industryApplication/industrial/19.industrial.jpg';
import industrial20 from '../styles/imgs/industryApplication/industrial/20.industrial.jpg';
import industrial21 from '../styles/imgs/industryApplication/industrial/21.industrial.jpg';

import ship1 from '../styles/imgs/industryApplication/ship/1.ship.png';
import ship2 from '../styles/imgs/industryApplication/ship/2.ship.png';
import ship3 from '../styles/imgs/industryApplication/ship/3.ship.jpg';
import ship4 from '../styles/imgs/industryApplication/ship/4.ship.jpg';
import ship5 from '../styles/imgs/industryApplication/ship/5.ship.jpg';
import ship6 from '../styles/imgs/industryApplication/ship/6.ship.jpg';
import ship7 from '../styles/imgs/industryApplication/ship/7.ship.jpg';
import ship8 from '../styles/imgs/industryApplication/ship/8.ship.jpg';
import ship9 from '../styles/imgs/industryApplication/ship/9.ship.jpg';

import public1 from '../styles/imgs/industryApplication/public/1.public.png';
import public2 from '../styles/imgs/industryApplication/public/2.public.png';
import public4 from '../styles/imgs/industryApplication/public/4.public.png';
import public5 from '../styles/imgs/industryApplication/public/5.public.png';
import public6 from '../styles/imgs/industryApplication/public/6.public.png';
import public7 from '../styles/imgs/industryApplication/public/7.public.png';
import public8 from '../styles/imgs/industryApplication/public/8.public.png';
import public9 from '../styles/imgs/industryApplication/public/9.public.png';
import public10 from '../styles/imgs/industryApplication/public/10.public.png';
import public11 from '../styles/imgs/industryApplication/public/11.public.png';
import public12 from '../styles/imgs/industryApplication/public/12.public.png';
import public13 from '../styles/imgs/industryApplication/public/13.public.jpg';

import government1 from '../styles/imgs/industryApplication/government/1.government.png';
import government2 from '../styles/imgs/industryApplication/government/2.government.png';
import government3 from '../styles/imgs/industryApplication/government/3.government.png';
import government4 from '../styles/imgs/industryApplication/government/4.government.jpg';
import government5 from '../styles/imgs/industryApplication/government/5.government.jpg';

import platform1 from '../styles/imgs/industryApplication/platform/1.platform.png';
import platform2 from '../styles/imgs/industryApplication/platform/2.platform.png';
import platform3 from '../styles/imgs/industryApplication/platform/3.platform.png';
import platform4 from '../styles/imgs/industryApplication/platform/4.platform.png';
import platform5 from '../styles/imgs/industryApplication/platform/5.platform.png';
import platform6 from '../styles/imgs/industryApplication/platform/6.platform.jpg';

import universities1 from '../styles/imgs/industryApplication/universities/1.universities.png';
import universities2 from '../styles/imgs/industryApplication/universities/2.universities.png';
import universities3 from '../styles/imgs/industryApplication/universities/3.universities.png';
import universities4 from '../styles/imgs/industryApplication/universities/4.universities.jpg';
import universities5 from '../styles/imgs/industryApplication/universities/5.universities.png';
import universities6 from '../styles/imgs/industryApplication/universities/6.universities.jpg';
import universities7 from '../styles/imgs/industryApplication/universities/7.universities.jpg';
import universities8 from '../styles/imgs/industryApplication/universities/8.universities.jpg';
import universities9 from '../styles/imgs/industryApplication/universities/9.universities.jpg';

export const datas = {
  industrial: {
    title: '工业企业',
    count: 8,
    list: [
      {
        img: industrial1,
        title: '中海壳牌石油化工有限公司',
        describe: <>
          客户特征：世界500强企业<br />
          地区/性质：广东省惠州市
        </>
      },
      {
        img: industrial2,
        title: '三星电子',
        describe: <>
          客户特征：世界500强企业<br />
          地区/性质：广东省惠州市
        </>
      },
      {
        img: industrial3,
        title: '京瓷集团',
        describe: <>
          客户特征：世界500强企业<br />
          地区/性质：广东省东莞市
        </>
      },
      {
        img: industrial4,
        title: '富士康集团',
        describe: <>
          客户特征：世界500强企业<br />
          地区/性质：广东省深圳市
        </>
      },
      {
        img: industrial5,
        title: '东风本田汽车有限公司',
        describe: <>
          客户特征：世界500强企业<br />
          地区/性质：广东省惠州市
        </>
      },
      {
        img: industrial6,
        title: '雪花啤酒(深圳)有限公司',
        describe: <>
          客户特征：世界500强企业<br />
          地区/性质：广东省深圳市
        </>
      },
      {
        img: industrial7,
        title: '鹏鼎控股(深圳)股份有限公司',
        describe: <>
          客户特征：业内名企<br />
          地区/性质：广东省深圳市
        </>
      },
      {
        img: industrial8,
        title: '深圳顺络电子股份有限公司',
        describe: <>
          客户特征：电感元件中国的国际支柱企业<br />
          地区/性质：广东省深圳市
        </>
      },
      {
        img: industrial9,
        title: '三菱化学化工原料(惠州)有限公司',
        describe: <>
          客户特征：业内名企<br />
          地区/性质：广东省惠州市
        </>
      },
      {
        img: industrial10,
        title: '青岛啤酒朝日有限公司',
        describe: <>
          客户特征：行业龙头企业<br />
          地区/性质：广东省惠州市
        </>
      },
      {
        img: industrial11,
        title: '广东联塑科技实业有限公司',
        describe: <>
          客户特征：世界上最大塑料管材生产商<br />
          地区/性质：广东省佛山市
        </>
      },
      {
        img: industrial12,
        title: '益海(广州)粮油工业有限公司',
        describe: <>
          客户特征：“金龙鱼”厂商<br />
          地区/性质：广东省广州市
        </>
      },
      {
        img: industrial13,
        title: '鹤山雅图仕印刷有限公司',
        describe: <>
          客户特征：行业龙头企业<br />
          地区/性质：广东省江门市
        </>
      },
      {
        img: industrial14,
        title: '信义玻璃汽车(深圳)有限公司',
        describe: <>
          客户特征：浮法玻璃帝国<br />
          地区/性质：广东省深圳市
        </>
      },
      {
        img: industrial15,
        title: '宏泉食品包装(清新)有限公司',
        describe: <>
          客户特征：业内名企<br />
          地区/性质：广东省清远市
        </>
      },
      {
        img: industrial16,
        title: '深圳市海源节能科技有限公司',
        describe: <>
          客户特征：业内名企<br />
          地区/性质：广东省深圳市
        </>
      },
      {
        img: industrial17,
        title: '深圳市妈湾电力有限公司',
        describe: <>
          客户特征：业内名企<br />
          地区/性质：广东省清远市
        </>
      },
      {
        img: industrial18,
        title: '亚太森博(广东)纸业有限公司',
        describe: <>
          客户特征：业内名企<br />
          地区/性质：广东省江门市
        </>
      },
      {
        img: industrial19,
        title: '乐昌南方水泥有限公司',
        describe: <>
          客户特征：水泥行业大型国企<br />
          地区/性质：广东省韶关市
        </>
      },
      {
        img: industrial20,
        title: '广东韶钢松山股份有限公司',
        describe: <>
          客户特征：行业龙头企业<br />
          地区/性质：广东省韶关市
        </>
      },
      {
        img: industrial21,
        title: '东莞德永佳纺织制衣有限公司',
        describe: <>
          客户特征：中国最大纺织企业之一<br />
          地区/性质：广东省清远市
        </>
      },
    ]
  },
  ship: {
    title: '船舶运输',
    count: 8,
    list: [
      {
        img: ship1,
        title: '招商集团蛇口集装箱码头',
        describe: <>
          客户特征：世界500强企业<br />
          地区/性质：广东省深圳市
        </>
      },
      {
        img: ship2,
        title: '友联船厂(深圳)有限公司',
        describe: <>
          客户特征：世界500强企业<br />
          地区/性质：广东省深圳市
        </>
      },
      {
        img: ship3,
        title: '浙江友联修造船有限公司',
        describe: <>
          客户特征：中国大型船舶制造企业<br />
          地区/性质：浙江省舟山市
        </>
      },
      {
        img: ship4,
        title: '招商重工(江苏)有限公司',
        describe: <>
          客户特征：中国大型船舶制造企业<br />
          地区/性质：江苏省南通市
        </>
      },
      {
        img: ship5,
        title: '招商局金陵船舶(江苏)有限公司',
        describe: <>
          客户特征：中国大型船舶制造企业<br />
          地区/性质：江苏省扬州市
        </>
      },
      {
        img: ship6,
        title: '招商局金陵船舶(威海)有限公司',
        describe: <>
          客户特征：中国大型船舶制造企业<br />
          地区/性质：山东省威海市
        </>
      },
      {
        img: ship7,
        title: '招商局金陵鼎恒船舶(扬州)有限公司',
        describe: <>
          客户特征：中国大型船舶制造企业<br />
          地区/性质：江苏省扬州市
        </>
      },
      {
        img: ship8,
        title: '舟山长宏国际船舶修造有限公司',
        describe: <>
          客户特征：中国大型船舶制造企业<br />
          地区/性质：浙江省舟山市
        </>
      },
      {
        img: ship9,
        title: '中国船舶集团广西造船有限公司',
        describe: <>
          客户特征：中国大型船舶制造企业<br />
          地区/性质：广西钦州市
        </>
      },
    ]
  },
  universities: {
    title: '高校平台',
    count: 5,
    list: [
      {
        img: universities1,
        title: '南方科技大学能源综合管理平台',
        describe: <>
          用户名称：南方科技大学<br />
          地区/性质：广东省深圳市
        </>
      },
      {
        img: universities2,
        title: '云南大学智慧能碳平台',
        describe: <>
          用户名称：云南大学<br />
          地区/性质：云南省昆明市
        </>
      },
      {
        img: universities3,
        title: '合肥工业大学智慧水务大数据平台',
        describe: <>
          用户名称：合肥工业大学翡翠湖校区<br />
          地区/性质：安徽省合肥市
        </>
      },
      {
        img: universities4,
        title: '电子科技大学长三角研究院能源管理平台',
        describe: <>
          用户名称：电子科技大学长三角研究院<br />
          地区/性质：浙江省衢州市
        </>
      },
      {
        img: universities5,
        title: '东莞理工学院能源管理平台',
        describe: <>
          用户名称：东莞理工学院<br />
          地区/性质：广东省东莞市
        </>
      },
      {
        img: universities6,
        title: '梧州一中节水监控综合管理平台',
        describe: <>
          用户名称：梧州市第一中学<br />
          地区/性质：广西梧州市
        </>
      },
      {
        img: universities7,
        title: '安徽工业大学智慧节水平台',
        describe: <>
          用户名称：安徽工业大学<br />
          地区/性质：安徽省马鞍山市
        </>
      },
      {
        img: universities8,
        title: '广州外国语职业技术学院智慧节水平台',
        describe: <>
          用户名称：广州外国语职业技术学院<br />
          地区/性质：广东省广州市
        </>
      },
      {
        img: universities9,
        title: '香港城市大学能源管理平台',
        describe: <>
          用户名称：香港城市大学<br />
          地区/性质：香港特别行政区
        </>
      }
    ]
  },
  public: {
    title: '公共建筑',
    count: 12,
    list: [
      {
        img:  public1,
        title: '天虹集团有限公司',
        describe: <>
          客户特征：上市公司、大型商超连锁企业集团<br />
          地区/性质：能管集团版
        </>
      },
      {
        img:  public4,
        title: '万达广场',
        describe: <>
          客户特征：中国最大的商业地产商<br />
          地区/性质：赤峰、满里州
        </>
      },
      {
        img:  public2,
        title: '琶洲会展中心',
        describe: <>
          客户特征：广交会所在地、中国最大会展馆<br />
          地区/性质：广东省广州市
        </>
      },
      {
        img:  public5,
        title: '深圳国贸大厦',
        describe: <>
          客户特征：“深圳速度”标志性建筑<br />
          地区/性质：广东省深圳市
        </>
      },
      {
        img:  public6,
        title: '东莞国际会展酒店',
        describe: <>
          客户特征：东莞最大酒店<br />
          地区/性质：广东省东莞市
        </>
      },
      {
        img: public11,
        title: '家乐福',
        describe: <>
          客户特征：世界500强<br />
          地区/性质：广东省东莞市
        </>
      },
      {
        img: public12,
        title: '东莞帝豪大厦',
        describe: <>
          客户特征：五星级酒店<br />
          地区/性质：广东省东莞市
        </>
      },
      {
        img: public9,
        title: '卫星大厦',
        describe: <>
          客户特征：央企航天科技集团下属物业<br />
          地区/性质：广东省深圳市
        </>
      },
      {
        img: public10,
        title: '东部华侨城',
        describe: <>
          客户特征：央企华侨城集团旅游地产<br />
          地区/性质：广东省深圳市
        </>
      },
      {
        img:  public8,
        title: '卓越集团',
        describe: <>
          客户特征：大型地产集团<br />
          地区/性质：广东省深圳市
        </>
      },
      {
        img:  public7,
        title: '东莞生态产业园',
        describe: <>
          客户特征：大型产业园<br />
          地区/性质：广东省东莞市
        </>
      },
      {
        img:  public13,
        title: '广西市场监督管理局',
        describe: <>
          客户特征：厅级公共机构<br />
          地区/性质：广西南宁市
        </>
      }
    ]
  },
  government: {
    title: '政府能管',
    count: 8,
    list: [
      {
        img: government1,
        title: '深圳市能源管理中心信息服务公共平台',
        describe: <>
          用户名称：深圳市经济贸易和信息化委员会<br />
          地区/性质：广东省深圳市
        </>
      },
      {
        img: government2,
        title: '深圳市南山区能源管理平台',
        describe: <>
          用户名称：深圳市南山区发改委<br />
          地区/性质：广东省深圳市
        </>
      },
      {
        img: government3,
        title: '东莞市重点用能单位能源信息管理系统一期',
        describe: <>
          用户名称：东莞市经济和信息化局<br />
          地区/性质：广东省东莞市
        </>
      },
      {
        img: government4,
        title: '韶关市重点用能单位能源信息管理系统设计及实施',
        describe: <>
          用户名称：韶关市节能监察中心<br />
          地区/性质：广东省韶关市
        </>
      },
      {
        img: government1,
        title: '惠州市重点用能单位能源信息管理系统',
        describe: <>
          客户性质：惠州市经济和信息化岛<br />
          地区/性质：广东省惠州市
        </>
      },
      {
        img: government2,
        title: '江门市重点用能企业能源减负安平台设计及实施',
        describe: <>
          客户性质：江门市经济和信息化岛<br />
          地区/性质：广东省江门市
        </>
      },
      {
        img: government3,
        title: '湖南宁乡国家级园能源管理平台',
        describe: <>
          客户性质：国家级智慧园区-宁乡经济开发区<br />
          地区/性质：湖南省宁乡市
        </>
      },
      {
        img: government5,
        title: '仲恺高新区能源管理平台及企业能源管理系统',
        describe: <>
          客户性质：惠州市仲恺高新区<br />
          地区/性质：广东省惠州市
        </>
      },
      {
        img: government4,
        title: '潮州供水枢纽智能节水监管平台',
        describe: <>
          客户性质：潮州供水枢纽<br />
          地区/性质：广东省潮州市
        </>
      }
    ]
  },
  platform: {
    title: '平台建设',
    count: 5,
    list: [
      {
        img: platform1,
        title: '能源管理平台',
        describe: <>
          {/* 状态：<span style={{ color: '#2dd549' }}>持续更新中</span><br /> */}
          方案：针对不同行业提供专业行业能源管理解决方案，从能耗数据采集到能耗分析，从设施管理到性能评估，从报表中心到能耗指标，拓远提供功能强大的的能源管理系统。
        </>
      },
      {
        img: platform6,
        title: '双碳管理平台',
        describe: <>
          {/* 状态：<span style={{ color: '#2dd549' }}>持续更新中</span><br /> */}
          方案：通过数字化技术实现碳资产智能化管理，带来降低碳排放成本、提高碳减排效率、优化企业管理流程、提升企业形象和品牌价值、推动碳市场发展等多方面效益。
        </>
      },
      {
        img: platform3,
        title: '资产管理平台',
        describe: <>
          {/* 状态：<span style={{ color: '#2dd549' }}>持续更新中</span><br /> */}
          方案：为企业降低运营成本，减少设备维护成本，管理资源设备，监督保养质量；帮助管理者方便处理各项关于资产的申请，提升审核审批流程效率。
        </>
      },
      {
        img: platform2,
        title: '智慧节水系统',
        describe: <>
          {/* 状态：<span style={{ color: '#2dd549' }}>持续更新中</span><br /> */}
          方案：通过系统数据化、监测预警、自动化维护保养，增强消防设施系统的可靠性，降低运营管理成本，实现消防智慧物联化。
        </>
      },
      {
        img: platform5,
        title: '智慧消防平台',
        describe: <>
          {/* 状态：<span style={{ color: '#2dd549' }}>持续更新中</span><br /> */}
          方案：通过系统数据化、监测预警、自动化维护保养，增强消防设施系统的可靠性，降低运营管理成本，实现消防智慧物联化。
        </>
      },
      {
        img: platform4,
        title: '智慧空调平台',
        describe: <>
          {/* 状态：<span style={{ color: '#2dd549' }}>持续更新中</span><br /> */}
          方案：运用全新的方案解决思路，不仅对中央空调各系统进行全面控制，而且采用了软件与硬件给合及系统集成技术，少了空调系统的能源浪费，达到最佳节能的目的。
        </>
      }
    ]
  }
};
