import { useState, useEffect } from 'react';
import './index.scss';

const SideNav = ({ menus, hideSection }) => {

  const [currentKey, setCurrentKey] = useState(null);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleScroll = (e) => {
    const scrollTop = e.target.documentElement.scrollTop;
    let key = null;
    if(hideSection && scrollTop > hideSection[0] && scrollTop < hideSection[1]) {
      
    } else {
      menus.forEach(m => {
        if(scrollTop >= m.top) key = m.key;
      });
    }
    setCurrentKey(key);
  }

  return <div className='sideNav' style={currentKey ? {} : { display: 'none' }}>
    {
      menus.map(item => <div
        key={item.key}
        onMouseOver={() => document.getElementsByTagName('html')[0].scrollTo({ top: item.top, behavior: "smooth" })}
        className={`sideNavItem ${currentKey === item.key && 'sideNavItemSelected'}`}
      >
      <div className='circle'>
        <div></div>
      </div>
      <div className='text'>{ item.title }</div>
    </div>)
    }
  </div>
}

export default SideNav;