// 头部 - 轮播图
import home_banner1 from '../styles/imgs/banner/home/banner1.png';
import home_banner2 from '../styles/imgs/banner/home/banner2.png';
import home_banner3 from '../styles/imgs/banner/home/banner3.png';

import productCenter_banner1 from '../styles/imgs/banner/productCenter/banner1.png';
import productCenter_banner2 from '../styles/imgs/banner/productCenter/banner2.png';
import productCenter_banner3 from '../styles/imgs/banner/productCenter/banner3.png';
import productCenter_banner4 from '../styles/imgs/banner/productCenter/banner4.png';
import productCenter_banner5 from '../styles/imgs/banner/productCenter/banner5.png';
import productCenter_banner6 from '../styles/imgs/banner/productCenter/banner6.png';

import technology_banner1 from '../styles/imgs/banner/technology/banner1.png';
import technology_banner2 from '../styles/imgs/banner/technology/banner2.png';
import technology_banner3 from '../styles/imgs/banner/technology/banner3.png';

import industryApplication_banner1 from '../styles/imgs/banner/industryApplication/banner1.png';
import industryApplication_banner2 from '../styles/imgs/banner/industryApplication/banner2.png';
import industryApplication_banner3 from '../styles/imgs/banner/industryApplication/banner3.png';
import industryApplication_banner4 from '../styles/imgs/banner/industryApplication/banner4.png';
import industryApplication_banner5 from '../styles/imgs/banner/industryApplication/banner5.png';
import industryApplication_banner6 from '../styles/imgs/banner/industryApplication/banner6.jpg';

import contact_banner1 from '../styles/imgs/banner/contact/banner1.png';

// 首页 - 产品中心
import icon_ProductCenter1 from '../styles/imgs/home/1.icon_ProductCenter_@1x.png';
import icon_ProductCenter2 from '../styles/imgs/home/2.icon_ProductCenter_@1x.png';
import icon_ProductCenter3 from '../styles/imgs/home/3.icon_ProductCenter_@1x.png';
import icon_ProductCenter4 from '../styles/imgs/home/4.icon_ProductCenter_@1x.png';
import icon_ProductCenter5 from '../styles/imgs/home/5.icon_ProductCenter_@1x.png';
import icon_ProductCenter6 from '../styles/imgs/home/6.icon_ProductCenter_@1x.png';
import img_ProductCenter1 from '../styles/imgs/home/7.image_ProductCenter_@1x.png';
import img_ProductCenter2 from '../styles/imgs/home/8.image_ProductCenter_@1x.png';
import img_ProductCenter3 from '../styles/imgs/home/9.image_ProductCenter_@1x.png';
import img_ProductCenter4 from '../styles/imgs/home/10.image_ProductCenter_@1x.png';
import img_ProductCenter5 from '../styles/imgs/home/11.image_ProductCenter_@1x.png';
import img_ProductCenter6 from '../styles/imgs/home/12.image_ProductCenter_@1x.png';

// 首页 - 技术核心
import img_CoreTechnology1 from '../styles/imgs/home/14.image_CoreTechnology_@1x.png';
import img_CoreTechnology2 from '../styles/imgs/home/15.image_CoreTechnology_@1x.png';
import img_CoreTechnology3 from '../styles/imgs/home/16.image_CoreTechnology_@1x.png';

// 首页 - 功能核心
import img_homeFunctionCore1 from '../styles/imgs/home/18.image_homeFunctionCore_@1x.png';
import img_homeFunctionCore2 from '../styles/imgs/home/17.image_homeFunctionCore_@1x.png';
import img_homeFunctionCore3 from '../styles/imgs/home/19.image_homeFunctionCore_@1x.png';

// 首页 - 行业应用
import icon_homeIndustryApplication1 from '../styles/imgs/home/20.icon_IndustryApplication_@1x.png';
import icon_homeIndustryApplication2 from '../styles/imgs/home/21.icon_IndustryApplication_@1x.png';
import icon_homeIndustryApplication3 from '../styles/imgs/home/22.icon_IndustryApplication_@1x.png';
import icon_homeIndustryApplication4 from '../styles/imgs/home/23.icon_IndustryApplication_@1x.png';
import icon_homeIndustryApplication5 from '../styles/imgs/home/24.icon_IndustryApplication_@1x.png';
import img_homeIndustryApplication1 from '../styles/imgs/home/24.image_IndustryApplication_@1x.png';
import img_homeIndustryApplication2 from '../styles/imgs/home/25.image_IndustryApplication_@1x.png';
import img_homeIndustryApplication3 from '../styles/imgs/home/26.image_IndustryApplication_@1x.png';
import img_homeIndustryApplication4 from '../styles/imgs/home/27.image_IndustryApplication_@1x.png';
import img_homeIndustryApplication5 from '../styles/imgs/home/28.image_IndustryApplication_@1x.png';


export const menus = [
  { name: '首页', url: '/', modulePath: '/' },
  {
    name: '产品中心',
    modulePath: 'productCenter',
    url: '/productCenter/carbon',
    children: [
      { name: '双碳应用平台', url: '/productCenter/carbon', type: 'carbon' },
      { name: '智慧能源平台', url: '/productCenter/energy', type: 'energy' },
      { name: '智慧节水平台', url: '/productCenter/water', type: 'water' },
      { name: '设备管理平台', url: '/productCenter/equipment', type: 'equipment' },
      { name: '智慧空调平台', url: '/productCenter/air', type: 'air' },
      { name: '政府监测平台', url: '/productCenter/government', type: 'government' }
    ]
  },
  {
    name: '技术核心',
    modulePath: 'technology',
    url: '/technology/source',
    children: [
      { name: '开源/国产化', url: '/technology/source', type: 'source' },
      { name: '大数据分析', url: '/technology/bigdata', type: 'bigdata' },
      { name: '数据通信', url: '/technology/communication', type: 'communication' }
    ]
  },
  {
    name: '行业应用',
    modulePath: 'industryApplication',
    url: '/industryApplication/industrial',
    children: [
      { name: '工业企业', url: '/industryApplication/industrial', type: 'industrial' },
      { name: '船舶运输', url: '/industryApplication/ship', type: 'ship' },
      { name: '高校平台', url: '/industryApplication/universities', type: 'universities' },
      { name: '公共建筑', url: '/industryApplication/public', type: 'public' },
      { name: '政府能管', url: '/industryApplication/government', type: 'government' },
      { name: '平台建设', url: '/industryApplication/platform', type: 'platform' }
    ]
  },
  {
    name: '关于我们',
    modulePath: 'contact',
    url: '/contact',
    // children: [
    //   { name: '公司简介', url: '/contact' },
    //   { name: '发展历程', url: '/contact/course' },
    //   { name: '公司展望', url: '/contact/outlook' },
    //   { name: '联系我们', url: '/contact/introduction' },
    // ]
  },
];
export const benners = {
  home: [
    {
      title: '产品中心',
      content: '提供行业数据产品和服务，全面满足您的业务场景和数据需求',
      img: home_banner1,
      url: menus[1].url
    },
    {
      title: '技术核心',
      content: '以卓越的数据技术为客户创造价值',
      img: home_banner2,
      url: menus[2].url
    },
    {
      title: '行业应用',
      content: '为各种规模的企业提供灵活易用的全业务链的大数据分析解决方案',
      img: home_banner3,
      url: menus[3].url
    }
  ],
  productCenter: [
    {
      title: '双碳平台服务解决方案',
      content: '通过科技的力量为碳排放量提供数据支持',
      img: productCenter_banner5,
      url: menus[1].children[0].url
    },
    {
      title: '智慧能源服务解决方案',
      content: '专注于各类能源监测，切实为客户提供良好的数据参考',
      img: productCenter_banner1,
      url: menus[1].children[1].url
    },
    {
      title: '智慧节水服务解决方案',
      content: '以专业的视角、过硬的技术为节水事业保驾护航',
      img: productCenter_banner3,
      url: menus[1].children[2].url
    },
    {
      title: '设备管理/资产管理服务解决方案',
      content: '将资产数据化，切实有效地管理各类资产，提升资产的能效',
      img: productCenter_banner4,
      url: menus[1].children[3].url
    },
    {
      title: '智慧空调服务解决方案',
      content: '对空调能耗监测管理，通过对数据的分析改善能源消耗',
      img: productCenter_banner2,
      url: menus[1].children[4].url
    },
    {
      title: '政府平台解决方案',
      content: '通过智慧平台为政府部门提供便捷的工作方式，提高工作效率',
      img: productCenter_banner6,
      url: menus[1].children[5].url
    }
  ],
  technology: [
    {
      title: '软件开源共享，走国产化道路',
      content: '先进开源技术为本 ，极致性价比模式为刚，提供专家级的企业信息化服务',
      img: technology_banner1,
      url: menus[2].children[0].url
    },
    {
      title: '利用大数据进行可视化分析',
      content: <>
        通过大数据对相关的能耗等输进行分析，深度解析其原因，从而<br />
        加强对于能源的管理
      </>,
      img: technology_banner2,
      url: menus[2].children[1].url
    },
    {
      title: '数据通讯管理',
      content: '安全、可靠、稳定的通讯环境是我们的立足之本',
      img: technology_banner3,
      url: menus[2].children[2].url
    }
  ],
  industryApplication: [
    {
      title: '工业企业的能耗、资产管理',
      content: '通过可视化的段对传统工业企业进行产能优化，实现线上线下一体化',
      img: industryApplication_banner1,
      url: menus[3].children[0].url
    },
    {
      title: '船舶能管信息化平台',
      content: '为船舶企业提供精确的能耗数据，优化能源利用分配方案',
      img: industryApplication_banner6,
      url: menus[3].children[4].url
    },
    {
      title: '高校能管信息化平台',
      content: '为高校提供精确的能耗数据，优化能源利用分配方案',
      img: industryApplication_banner5,
      url: menus[3].children[4].url
    },
    {
      title: '公共建筑能耗管理',
      content: <>
        对公共建筑能耗进行监测分析，追根溯源，实现能耗预测<br />
        并制定行之有效的节能方案
      </>,
      img: industryApplication_banner2,
      url: menus[3].children[1].url
    },
    {
      title: '政府部门能源管理监控',
      content: '利用信息化的的手段对相关政府部门的能耗加以监控，以便制定相关的节能方案',
      img: industryApplication_banner3,
      url: menus[3].children[2].url
    },
    {
      title: '精益求精的平台建设',
      content: '多种类的能管平台实现能源的精准定位监测',
      img: industryApplication_banner4,
      url: menus[3].children[3].url
    }
  ],
  contact: [
    { img: contact_banner1 }
  ]
};

export const sideNavMenus = {
  home: [
    { key: 'sideNavItem1', title: '产品中心', top: 712-72 },
    { key: 'sideNavItem2', title: '技术核心', top: 712+1127-72 },
    // { key: 'sideNavItem3', title: '功能核心', top: 712+1127+650 },
    { key: 'sideNavItem4', title: '行业应用', top: 712+1127+650-72 }
  ],
  contact: [
    { key: 'sideNavItem1', title: '公司简介', top: 480 },
    { key: 'sideNavItem2', title: '发展历程', top: 1030 },
    { key: 'sideNavItem3', title: '公司展望', top: 1690 },
    { key: 'sideNavItem4', title: '联系我们', top: 2230 }
  ]
};

export const homeProductCenter = [
  {
    name: '双碳应用平台',
    describe: <>
      为达到双碳平台的下降目标，平台提供对能源、<br />
      工业等行业进行进行智慧监管控，实现节能减排，<br />
      碳排“零排放”。
    </>,
    url: menus[1].children[0].url,
    icon: icon_ProductCenter5,
    backImg: img_ProductCenter5
  },
  {
    name: '智慧能源平台',
    describe: <>
      是通过集数据采集、数据管理和数据分析，从而对能源<br />
      监控、能源统计、能源消费、能耗设备的智能<br />
      化管理服务系统。
    </>,
    url: menus[1].children[1].url,
    icon: icon_ProductCenter1,
    backImg: img_ProductCenter1
  },
  {
    name: '智慧节水平台',
    describe: <>
      运用多个自有专利软件技术，集合先进的仪器、表通迅<br />
      等技术，为园区搭建“供水管网系统智能化<br />
      管理--监测、管理、控制节水云平台” 。
    </>,
    url: menus[1].children[2].url,
    icon: icon_ProductCenter3,
    backImg: img_ProductCenter3
  },
  {
    name: '设备管理平台',
    describe: <>
      通过设备运行管理、维护保养等系统管理功能，全面掌<br />
      控设备运行状况，延长设备运行寿命，节约运<br />
      营费用，实现设备的全生命周期管理。
    </>,
    url: menus[1].children[3].url,
    icon: icon_ProductCenter4,
    backImg: img_ProductCenter4
  },
  {
    name: '智慧空调平台',
    describe: <>
      实现了对中央空调冷冻水系统、冷却水系统及冷却塔风<br />
      机系统的变流量智能控制。减少了空调系统的<br />
      能源浪费，达到最佳节能的目的。
    </>,
    url: menus[1].children[4].url,
    icon: icon_ProductCenter2,
    backImg: img_ProductCenter2
  },
  {
    name: '政府监测平台',
    describe: <>
      通过监管系统平台的建设，政府节能管理人员可随时查<br />
      取重点用能单位能耗数据，掌握异常趋势，实时<br />
      分析节能减排目标完成情况和能源储备情况。
    </>,
    url: menus[1].children[5].url,
    icon: icon_ProductCenter6,
    backImg: img_ProductCenter6
  }
];

export const homeCoreTechnology = [
  {
    name: '开源/国产化',
    icon: img_CoreTechnology1,
    url: menus[2].children[0].url,
    describe: <ul>
      <li>开放、共享，软件开源化</li>
      <li>兼容鸿蒙，对接国产化</li>
      <li>高效、稳定、安全智慧物联网平台</li>
    </ul>
  },
  {
    name: '大数据分析',
    icon: img_CoreTechnology2,
    url: menus[2].children[1].url,
    describe: <ul>
      <li>提供可视化界面。</li>
      <li>看板共享功能及健全的权限管理体系。</li>
      <li>采集足够全面的属性、维度、指标，数据更加优质。</li>
    </ul>
  },
  {
    name: '数据通讯',
    icon: img_CoreTechnology3,
    url: menus[2].children[2].url,
    describe: <ul>
      <li>一键全自动部署方案。</li>
      <li>海量数据处理能力。</li>
      <li>完善的运维体系，确保数据安全。</li>
    </ul>
  }
];

export const homeFunctionCore = [
  { img: img_homeFunctionCore1 },
  { img: img_homeFunctionCore2 },
  { img: img_homeFunctionCore3 }
];

export const homeIndustryApplication = [
  {
    name: '平台建设',
    icon: icon_homeIndustryApplication1,
    backImg: img_homeIndustryApplication1,
    url: '/industryApplication/platform',
    describe: <>
      针对不同行业提供行业能源管理解决方案，<br />
      建立一个全局性的能源信息管理系统。提高<br />
      运维效率，降低能源成本；提升客户体验，<br />
      提供舒适环境。
    </>
  },
  {
    name: '工业企业',
    icon: icon_homeIndustryApplication2,
    backImg: img_homeIndustryApplication2,
    url: '/industryApplication/industrial',
    describe: <>
      工业企业如何打造多无化数据可视化与数据<br />
      应用场景，让数据驱动决策。对能耗进行统<br />
      计和分析、能耗及设施管理的特点，提供适<br />
      合的能源管理解决方案。
    </>
  },
  {
    name: '商业建筑',
    icon: icon_homeIndustryApplication3,
    backImg: img_homeIndustryApplication3,
    url: '/industryApplication/public',
    describe: <>
    对整个楼宇的能源进行监管、统计、分析、<br />
    比较，即时发现能耗及设施效率薄弱环节，<br />
    持续改进能源及设施使用效率，使得数据分<br />
    析和展示变得简单而个性化。
    </>
  },
  {
    name: '政府能管',
    icon: icon_homeIndustryApplication4,
    backImg: img_homeIndustryApplication4,
    url: '/industryApplication/government',
    describe: <>
      通过监管系统平台的建设，一站式大数据分<br />
      析解决方案，为政府宏观节能管理提高服务<br />
      效与安全保障，构建智慧政府服务平台，打<br />
      造数据化运营的智慧政府。
    </>
  },
  {
    name: '高校平台',
    icon: icon_homeIndustryApplication5,
    backImg: img_homeIndustryApplication5,
    url: '/industryApplication/universities',
    describe: <>
      通过建设“校园能耗监测管理平台”，采集<br />
      建筑电、水等能源消耗数据，并对用能数<br />
      据进行细分并进行分析，促进学校能源资<br />
      源合理配置，提高学校能源利用水平。
    </>
  }
];

export const courseList = [
  { year: 2008, event : '拓远成立，定位资源类/节能物联网信息化平台开发' },
  { year: 2009, event : '开发“工业企业的能源管理平台”，并应用于光台电子（台资）' },
  { year: 2010, event : '开发“公共建筑能源管理平台”，并应用于天虹集团深圳沙井门店' },
  { year: 2011, event : '首次承接大型工业企业全方位能源管理平台建设，“万力轮胎”上千监测点' },
  { year: 2012, event : '承建广东省第一个地市级政府能源监管平台——东莞市能源管理平台' },
  { year: 2013, event : '惠州、江门、韶关等政府能源管理平台建设' },
  { year: 2014, event : '承建深圳市政府能源监管平台——“深圳节能在线”' },
  { year: 2015, event : '蛇口码头、中海壳牌、富士康鹏鼎等多领域平台项目承建' },
  { year: 2016, event : '国家高新技术企业' },
  { year: 2017, event : '开发应用于船舶制造领域的能源管控平台' },
  { year: 2018, event : '开发资产设备管理系统平台' },
  { year: 2019, event : '南方科技大学能源管理平台建设' },
  { year: 2020, event : '开发应用于学校/医院等公共机构/园区的“智慧节水平台”' },
  { year: 2021, event : '开发基于大数据的能源决策支撑分析系统' },
  { year: 2022, event : '“双碳”应用平台开发' },
  { year: 2023, event : '智慧能碳管理平台开发' },
];