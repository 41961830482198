import introduce1 from '../styles/imgs/productCenter/introduce1.png';
import introduce2 from '../styles/imgs/productCenter/introduce2.png';
import introduce3 from '../styles/imgs/productCenter/introduce3.png';

// energy
import energyBrightSpot1 from '../styles/imgs/productCenter/energy/brightSpot1.png';
import energyBrightSpot2 from '../styles/imgs/productCenter/energy/brightSpot2.png';
import energyBrightSpot3 from '../styles/imgs/productCenter/energy/brightSpot3.png';
import energyBrightSpot4 from '../styles/imgs/productCenter/energy/brightSpot4.png';
import energyArchitecture1 from '../styles/imgs/productCenter/energy/architecture1.png';
import energyShow1 from '../styles/imgs/productCenter/energy/show1.png';
import energyShow2 from '../styles/imgs/productCenter/energy/show2.png';
import energyShow3 from '../styles/imgs/productCenter/energy/show3.png';
import energyShow4 from '../styles/imgs/productCenter/energy/show4.png';
import energyShow5 from '../styles/imgs/productCenter/energy/show5.png';
import energyShow6 from '../styles/imgs/productCenter/energy/show6.png';
import energyShow7 from '../styles/imgs/productCenter/energy/show7.png';
import energyShow8 from '../styles/imgs/productCenter/energy/show8.png';
import energyShow9 from '../styles/imgs/productCenter/energy/show9.png';
import energyShow10 from '../styles/imgs/productCenter/energy/show10.png';
import energyShow11 from '../styles/imgs/productCenter/energy/show11.png';
import energyShow12 from '../styles/imgs/productCenter/energy/show12.png';
import energyShow13 from '../styles/imgs/productCenter/energy/show13.png';
import energyShow14 from '../styles/imgs/productCenter/energy/show14.png';

// air
import airBrightSpot1 from '../styles/imgs/productCenter/air/brightSpot1.png';
import airBrightSpot2 from '../styles/imgs/productCenter/air/brightSpot2.png';
import airBrightSpot3 from '../styles/imgs/productCenter/air/brightSpot3.png';
import airBrightSpot4 from '../styles/imgs/productCenter/air/brightSpot4.png';
import airArchitecture1 from '../styles/imgs/productCenter/air/architecture1.png';
import airshow1 from '../styles/imgs/productCenter/air/show1.png';
import airshow2 from '../styles/imgs/productCenter/air/show2.png';
import airshow3 from '../styles/imgs/productCenter/air/show3.png';
import airshow4 from '../styles/imgs/productCenter/air/show4.png';
import airshow5 from '../styles/imgs/productCenter/air/show5.png';

// water
import waterBrightSpot1 from '../styles/imgs/productCenter/water/brightSpot1.png';
import waterBrightSpot2 from '../styles/imgs/productCenter/water/brightSpot2.png';
import waterBrightSpot3 from '../styles/imgs/productCenter/water/brightSpot3.png';
import waterBrightSpot4 from '../styles/imgs/productCenter/water/brightSpot4.png';
import waterArchitecture1 from '../styles/imgs/productCenter/water/architecture1.png';
import watershow1 from '../styles/imgs/productCenter/water/show1.png';
import watershow2 from '../styles/imgs/productCenter/water/show2.png';
import watershow3 from '../styles/imgs/productCenter/water/show3.png';
import watershow4 from '../styles/imgs/productCenter/water/show4.png';
import watershow5 from '../styles/imgs/productCenter/water/show5.png';
import watershow6 from '../styles/imgs/productCenter/water/show6.png';
import watershow7 from '../styles/imgs/productCenter/water/show7.png';
import watershow8 from '../styles/imgs/productCenter/water/show8.png';
import watershow9 from '../styles/imgs/productCenter/water/show9.png';
import watershow10 from '../styles/imgs/productCenter/water/show10.png';
import watershow11 from '../styles/imgs/productCenter/water/show11.png';

// equipment
import equipmentBrightSpot1 from '../styles/imgs/productCenter/equipment/brightSpot1.png';
import equipmentBrightSpot2 from '../styles/imgs/productCenter/equipment/brightSpot2.png';
import equipmentBrightSpot3 from '../styles/imgs/productCenter/equipment/brightSpot3.png';
import equipmentBrightSpot4 from '../styles/imgs/productCenter/equipment/brightSpot4.png';
import equipmentArchitecture1 from '../styles/imgs/productCenter/equipment/architecture1.png';
import equipmentArchitecture2 from '../styles/imgs/productCenter/equipment/architecture2.png';
import equipmentshow1 from '../styles/imgs/productCenter/equipment/show1.png';
import equipmentshow2 from '../styles/imgs/productCenter/equipment/show2.png';
import equipmentshow3 from '../styles/imgs/productCenter/equipment/show3.png';
import equipmentshow4 from '../styles/imgs/productCenter/equipment/show4.png';
import equipmentshow5 from '../styles/imgs/productCenter/equipment/show5.png';
import equipmentshow6 from '../styles/imgs/productCenter/equipment/show6.png';
import equipmentshow7 from '../styles/imgs/productCenter/equipment/show7.png';
import equipmentshow8 from '../styles/imgs/productCenter/equipment/show8.png';
import equipmentshow9 from '../styles/imgs/productCenter/equipment/show9.png';
import equipmentshow10 from '../styles/imgs/productCenter/equipment/show10.png';
import equipmentshow11 from '../styles/imgs/productCenter/equipment/show11.png';
import equipmentshow12 from '../styles/imgs/productCenter/equipment/show12.png';
import equipmentshow13 from '../styles/imgs/productCenter/equipment/show13.png';
import equipmentshow14 from '../styles/imgs/productCenter/equipment/show14.png';

// carbon
import carbonBrightSpot1 from '../styles/imgs/productCenter/carbon/brightSpot1.png';
import carbonBrightSpot2 from '../styles/imgs/productCenter/carbon/brightSpot2.png';
import carbonBrightSpot3 from '../styles/imgs/productCenter/carbon/brightSpot3.png';
import carbonBrightSpot4 from '../styles/imgs/productCenter/carbon/brightSpot4.png';
import carbonArchitecture1 from '../styles/imgs/productCenter/carbon/architecture1.png';
import carbonshow1 from '../styles/imgs/productCenter/carbon/show1.png';
import carbonshow2 from '../styles/imgs/productCenter/carbon/show2.png';
import carbonshow3 from '../styles/imgs/productCenter/carbon/show3.png';
import carbonshow4 from '../styles/imgs/productCenter/carbon/show4.png';
import carbonshow5 from '../styles/imgs/productCenter/carbon/show5.png';
import carbonshow6 from '../styles/imgs/productCenter/carbon/show6.png';
import carbonshow7 from '../styles/imgs/productCenter/carbon/show7.png';
import carbonshow8 from '../styles/imgs/productCenter/carbon/show8.png';

// government

export const introducedImgs = [ introduce1, introduce2, introduce3 ];
export const introduced = {
  energy: [
    {
      title: '计量传感网络层',
      describe: <>
        主要由计量各种能源的计量仪器仪表组成,包括多
        功能电能表(规格型号:PD194Z-2S4A)、油表、蒸
        汽表、燃气表、水表、煤计量表等,这些表计对应
        用能耗使用进行精确计量。所采用的计量仪器仪表
        是具有通讯功能的智能仪表。这些智能仪表通过通
        信线路与数据采集设备相连接。
      </>
    },
    {
      title: '数据采集传输层',
      describe: <>
        主要由数据采集通讯设备组成。包括能效网关、
        协议转换等。能效网关与各智能仪表通过通信线
        路相连, 对各智能仪表的数据进行实时采集，并
        经过企业局域网把数据、上传到数据服务器。采
        集数据由DCS通过OPC传输到数据服务器。
      </>
    },
    {
      title: '能源监测系统层',
      describe: <>
        主要由企业内部IT网络、采集服务器、数据存储
        服务器,应用服务器、软件系统等设备及软件组成
        ;软件系统包括系统软件(操作系统数据库)、 能
        源监控软件及其他应用软件。企业能源管理中心
        将为用户提供数据的分析展示、统计、分析、分
        类管理和建档。对节能措施的方案制定、 能源统
        计、能耗分析与预测、用能质量的评估以及能源
        审计。
      </>
    },
  ],
  air: [
    {
      title: '智控节能',
      describe: <>
        采用了软件与硬件给合及系统集成技术，将各个
        控制系统在物理、逻辑和功能上互联体，将建筑
        有效分布区域内的人员分布信息、温湿度及环境
        信息进行就地分析和处理，上传到智能服务器和
        云平台，云平台可提数据共享、运行监控、故障
        报警、供能耗分析与故障诊断、工单管理、数据
        分析、运营报告等多项功能并输出管理报表。
      </>
    },
    {
      title: '运维管理',
      describe: <>
        可以实现管理本地化和服务云端化,可以将分散的
        设备数据转化为系统的管理数据,变被动式运维为
        主动式运维，平台可以结合客户的设备技术特征, 
        在云端策略和经验数据的指导下,实现对中央空调
        系统的统一 管理，同时结合巡游控制技术对耗能
        设备进行优化控制,节约设备与系统能耗，打造智
        慧、节能的设备生命体。
      </>
    },
    {
      title: '变流量智能控制',
      describe: <>
        主要由企业内部IT网络、采集服务器、数据存储
        服务器,应用服务器、软件系统等设备及软件组成
        ;软件系统包括系统软件(操作系统数据库)、 能
        源监控软件及其他应用软件。企业能源管理中心
        将为用户提供数据的分析展示、统计、分析、分
        类管理和建档。对节能措施的方案制定、 能源
        统计、能耗分析与预测、用能质量的评估以及能
        源审计。
      </>
    },
  ],
  water: [
    {
      title: '计量传感网络层',
      describe: <>
        主要由计量各种能源的计量仪器仪表组成,包括多
        功能电能表(规格型号:PD194Z-2S4A)、油表、蒸
        汽表、燃气表、水表、煤计量表等,这些表计对应
        用能耗使用进行精确计量。所采用的计量仪器仪表
        是具有通讯功能的智能仪表。这些智能仪表通过通
        信线路与数据采集设备相连接。
      </>
    },
    {
      title: '数据采集传输层',
      describe: <>
        主要由数据采集通讯设备组成。包括能效网关、
        协议转换等。能效网关与各智能仪表通过通信线
        路相连, 对各智能仪表的数据进行实时采集，并
        经过企业局域网把数据、上传到数据服务器。采
        集数据由DCS通过OPC传输到数据服务器。
      </>
    },
    {
      title: '能源监测系统层',
      describe: <>
        主要由企业内部IT网络、采集服务器、数据存储
        服务器,应用服务器、软件系统等设备及软件组成
        ;软件系统包括系统软件(操作系统数据库)、 能
        源监控软件及其他应用软件。企业能源管理中心将
        为用户提供数据的分析展示、统计、分析、分类管
        理和建档。对节能措施的方案制定、 能源统计、能
        耗分析与预测、用能质量的评估以及能源审计。
      </>
    },
  ],
  equipment: [
    {
      title: '整合资源，杜绝浪费',
      describe: <>
        针对企业信息化的发展慢，企业运营成本高昂，
        设备价格高，部分零件外国进口而导致预定时间
        较长，维护成本高;在厂区的设备管理过程中出现
        了很多资源管理不当，各种浪费现象难以管理。
      </>
    },
    {
      title: '优化流程，提高效率',
      describe: <>
        设备备维修周期长，线下审核审批流程繁琐效率
        低；设备的使用和保养上不够规范，难以监督保
        养质量，导致每年维修保养和购买新设备的企业
        支出成本难以控制，不利于企业在长期的激烈的
        竞争当中，保持可持续发展。
      </>
    },
    {
      title: '资产监管，降低成本',
      describe: <>
        本系统信息化地管理船厂的重要资产和设备，在
        设备的维修、保养上实现信息化管理，直到进行
        资产设备的全生命周期管理，实现提高管理效率
        ，降低企业运营成本。
      </>
    },
  ],
  carbon: [
    {
      title: '碳资产管理',
      describe: <>
        是通过监测、评估和管理企业或政府的碳排放情况和减排潜力，
        为制定碳减排计划提供科学依据的方法。它包括碳排放监测、评估、碳资产管理和碳减排计划制定四个方面。
        碳资产管理可以促进企业或政府的可持续发展，减少碳排放，减缓气候变化。

      </>
    },
    {
      title: '碳排放管理',
      describe: <>
        是指监测、计算和报告企业或政府的碳排放情况，以便及时发现异常并采取措施降低排放量。
        碳排放监测使用传感器、监测设备等技术手段，碳排放计算使用标准公式、碳排放清单等工具，
        碳排放报告包括基本信息、排放量、来源等内容。碳排放管理有助于制定碳减排计划，提高效率和效果。
      </>
    },
    {
      title: '碳足迹管理',
      describe: <>
        是通过测量和分析企业的产品和服务的碳排放，帮助企业了解其碳足迹来源和影响，并采取措施减少碳排放。
        碳足迹测量包括直接和间接排放，碳足迹分析可以找出影响因素，碳足迹降低可以通过改进生产工艺、优化供应链等方式实现。
        碳足迹管理有助于企业降低碳排放，提高社会责任感和形象，制定更加科学合理的碳减排计划。
      </>
    },
  ],
  government: [
    {
      title: '建设平台',
      describe: <>
        拓远为政府及相关监管机构提供能源监管平台解
        决方案，通过监管系统平台的建设，政府节能管
        理人员可随时查取重点用能单位能耗数据，掌握
        异常趋势，实时分析节能减排目标完成情况和能
        源储备情况，为政府宏观节能管理提供决策依据。
      </>
    },
    {
      title: '功能强大',
      describe: <>
        通过强大的系统分析功能，帮助企业查找自身存
        在的问题，促进节约利用能源；通过异常分析、
        对标分析、预测预警系统快速准确地查找问题点
        ，确定企业节能潜力，并可通过专家咨询系统寻
        求解决途径。
      </>
    },
    {
      title: '高效服务',
      describe: <>
        通过统计审计和对比分析，确定动态采集数据的
        真实性，为建立企业能源管理体系服务。同时，
        在企业节能管理技术上加大引导说明和监督指导
        ，帮助企业更好的科学使用能源，实现节约能源
        的目标。
      </>
    },
  ],
};

export const brightSpot = {
  energy: [
    {
      title: '数据采集',
      icon: energyBrightSpot1,
      describe: `数据采集是WEAS进行数据统计分 析的基础，实现对企业运行数据实 时监视、报警、分析、计算和统计 等功能。采集类型包括电、水、 煤、汽等多能源种类。`
    },
    {
      title: '实时监控',
      icon: energyBrightSpot2,
      describe: `通过查看能源系统图、电力一次图、水管网图、蒸汽图、设备列表 等，用户可以系统的了解能源消耗 的整体分布情况。`
    },
    {
      title: '在线分析',
      icon: energyBrightSpot3,
      describe: `可分级查看各区域、线路和设备等 能耗单元的详细数据,不同设备可 进行对比分析;可查看电流、功率 等监测数据的实时曲线,用户可以 直观、方便的观察设备的运行状 态,进而提出改进措施。`
    },
    {
      title: '自定义报表',
      icon: energyBrightSpot4,
      describe: `按时间周期自动生成日报表、周/ 月报表、季/年报表。可根据用户 需求个性化定制报表,满足自动抄 表、不同类型数据对比的需求。`
    },
  ],
  air: [
    {
      title: '调节策略，远程控制',
      icon: airBrightSpot1,
      describe: `自动调节，实现多台供空调的智能运行，规避基于经验的运行调节，实现节能。智能阀门远程控制，无需在现场，提高管网调解效率，及时解决用户问题。`
    },
    {
      title: '画面编辑，智能配图',
      icon: airBrightSpot2,
      describe: `以AI人工智能为基础，对设备及系统关键指标进行故障诊断，在第一间发现和提示报警，协助运维人员快速定位排查问题，提高运维效率,降低发电量损失。`
    },
    {
      title: '事件自动提醒',
      icon: airBrightSpot3,
      describe: `系统自动推送故障报警、亚健康、发电量偏低等信息，并可跳转至问题设备，快速定位问题，提高报警处理效率。`
    },
    {
      title: '智能分析预警',
      icon: airBrightSpot4,
      describe: `按时间周期自动生成日报表、周/ 月报表、季/年报表。可根据用户 需求个性化定制报表，满足自动抄 表、不同类型数据对比的需求。`
    },
  ],
  water: [
    {
      title: '数据采集',
      icon: waterBrightSpot1,
      describe: `数据采集是WEAS进行数据统计分 析的基础，实现对企业运行数据实 时监视、报警、分析、计算和统计 等功能。采集类型包括电、水、 煤、汽等多能源种类。`
    },
    {
      title: '实时监控',
      icon: waterBrightSpot2,
      describe: `通过查看能源系统图、电力一次图、水管网图、蒸汽图、设备列表 等，用户可以系统的了解能源消耗 的整体分布情况。`
    },
    {
      title: '在线分析',
      icon: waterBrightSpot3,
      describe: `可分级查看各区域、线路和设备等 能耗单元的详细数据,不同设备可 进行对比分析;可查看电流、功率 等监测数据的实时曲线,用户可以 直观、方便的观察设备的运行状 态,进而提出改进措施。`
    },
    {
      title: '自定义报表',
      icon: waterBrightSpot4,
      describe: `按时间周期自动生成日报表、周/ 月报表、季/年报表。可根据用户 需求个性化定制报表,满足自动抄 表、不同类型数据对比的需求。`
    },
  ],
  equipment: [
    {
      title: '无纸化办公',
      icon: equipmentBrightSpot1,
      describe: `具备无纸化办公属性的硬、软件可以大大减少重复劳动，可以使各个部门、各个环节的单独处理工作串联起来，也能处理流程上多环节的任务。可以进行不同环节批复与查询。`
    },
    {
      title: '全面监控，资产安全',
      icon: equipmentBrightSpot2,
      describe: `对资产使用及变动信息进行全程监控管理，全程把握资产动态，防范资产流失、损坏，确保资产安全。`
    },
    {
      title: '技术先进，数据准确',
      icon: equipmentBrightSpot3,
      describe: `采用先进的二维码/RFID技术对资产从购置、领用、转移、盘点、清理到报废等方面进行全方位准确统计，突破以往手工统计的繁重，节约人力物力，缩短清查时间，保证了资产管理工作的准确快速。`
    },
    {
      title: '管理到岗、责任到人',
      icon: equipmentBrightSpot4,
      describe: `资产管理具有信息量大，涉及岗位、人员众多的特点，通过对资产信息的收集、监控，实现资产与使用单位、部门、人员的同步管理，真正实现“分级管理、责任到人”。`
    },
  ],
  carbon: [
    {
      title: '可持续',
      icon: carbonBrightSpot1,
      describe: `具有可持续性的产品与循环经济效益，减少能耗损失；优化能源管理和全生命周期效率。`
    },
    {
      title: '韧性',
      icon: carbonBrightSpot2,
      describe: `具有可靠性、连续性，设备可用提升、场所安全性提升。优化能源效率，提升运维及管理效率具备各细分领域/行业的专业知识和能力。`
    },
    {
      title: '高效',
      icon: carbonBrightSpot3,
      describe: `数字化、精益化，资本支出缩减，运营支出缩减。远程操作和管理，预连接并降低风险；开放，可互操作的架构和平台。`
    },
    {
      title: '灵活',
      icon: carbonBrightSpot4,
      describe: `分散、及时调度， 成本节约。灵活应对分布式新能源接入的间歇性，合理削峰填谷，控制成本；最大限度地利用分布式能源，灵活满足需求。`
    },
  ]
};

export const architecture = {
  energy: [ energyArchitecture1 ],
  air: [ airArchitecture1 ],
  water: [ waterArchitecture1 ],
  equipment: [ equipmentArchitecture1, equipmentArchitecture2 ],
  carbon: [ carbonArchitecture1 ],
};

export const show = {
  carbon: [ 
    { img: carbonshow1, title: '双碳总看板', text: '双碳管理系统中关键性总结数据，看板集从碳排放、碳消减、碳资产三个维度进行数据展示。' },
    { img: carbonshow2, title: '达峰路径', text: '基于企业碳达峰路径规划及目标情况，企业实现碳达峰的过程中，需要细化实施方案、监测节能降碳效果、控制项目进度等等。本系统系统集成了包括绿色制造、绿色能源、低碳建筑、绿色管理、低碳科技在内的碳达峰实施路径。通过达峰路径的监管，能够将减排方法应用到企业执行层面，定制碳达峰实施方案。' },
    { img: carbonshow3, title: '碳报表', text: '为满足企业在生产过程中的数据、报表展示等需求，设置碳报表界面，集成了碳排放、碳抵消及碳资产三种报表导出功能，该功能可以帮助企业对数据进行统计分析、分类、按时间和区间进行汇总，从而辅助企业快速建立台账和报表。' },
    { img: carbonshow4, title: '碳监测', text: '基于现有能源实时监测系统，本项目碳排放中的电力数据以实时连续数据传输进行展示，其他间歇监测数据则通过后台账户对数据进月度更新。' },
    { img: carbonshow5, title: '碳消减看板', text: '内置不同碳消减措施的减排算法，量化清洁能源、系统改造等减排项目的降碳效果，对比不同减排项目的运行情况，通过碳排放管理系统监督和管理项目的碳排放情况，并定期评估和更新碳排放目标和减排措施。' },
    { img: carbonshow6, title: '碳资产', text: '碳资产管理界面可以直观的展示企业碳资产情况，同时连接碳交易市场，辅助企业对核证减排量的买卖交易进行决策。' },
    { img: carbonshow7, title: '碳预测', text: '碳预测板块包括碳预测、部门总量预测以及强度预测三部分，通过预测未来碳排放趋势，分解年度总量考核以及强度考核指标，加强企业碳排放预测方面的管理。通过碳监测模块数据计算，可以起到辅助企业对新增减排或增量项目进行辅助决策，以此确定项目实施时间、建设强度等等。' },
    { img: carbonshow8, title: '总量绩效', text: '基于企业总量和强度考核制度，针对集团设立的碳达峰、碳中和的目标，结合企业碳排放清单制定适当的碳排放目标，基于这两大目标进行碳排放总量及强度绩效考核，细化到各部门及产线目标；同时系统将年度、月度等时间范围内的目标完成情况进行统计。该部分具有警示提供功能。' }
  ],
  energy: [
    { img: energyShow1, title: '登录页', text: '系统登录页，通过不同账号控制权限，从而实现不同用户进入系统后展示其所关心或所负责的模块，从而提高用户的工作效率，同时也可极大地避免用户误操作等情况、保证数据的安全可靠性。' },
    { img: energyShow2, title: '数据看板', text: '数据看板内置较多的数据模块模板，可根据不同用户所关心的数据等构建该用户的专属看板；看板内的不同模块还可以根据用户需求自定义调整模块的大小、位置等。' },
    { img: energyShow3, title: '报修管理', text: '从移动端进行报修数，在pc端统计所有的报修及维修数据，清楚地展示所有的报修、维修情况的详细数据信息。' },
    { img: energyShow4, title: '报表统计', text: '通过不同形式的报表满足企业在生产过程的数据、报表展示等需求，主要包含了年、月、日报表，峰平谷报表，用能精细化报表等各种样式的报表，该功能可以帮助企业对数据进行统计分析、分类、按时间和区间进行汇总。' },
    { img: energyShow5, title: '变压器', text: '获取电压器容量、各相的电流各相的、电压、功率因数、三相平衡率、负载以及负载变化趋势等数据信息。' },
    { img: energyShow6, title: '表底数', text: '主要用于导出设备的表底数，查看数据上传是否有异常等，也可预览查看该设备不同参数最近的表底数详情' },
    { img: energyShow7, title: '峰平谷', text: '分别统计峰、平、谷等不同时间段内的用能及费用等数据，以及各时段的用能、费用占比等，从宏观上监控企业不同时段的用能数据详情，为企业用能调整提供有效的参考依据。' },
    { img: energyShow8, title: '告警统计', text: '统计设备的告警信息，包括数据告警与离线告警，同时根据设备的告警级别进行告警的分类统计。' },
    { img: energyShow9, title: '能源结构图', text: '直观地展示企业的能源结构分布情况等信息，并计算各设备用能平衡差，通过用能平衡差可检测各用能支路是否有能有能量损耗或数据异常等情况。' },
    { img: energyShow10, title: '设备管理', text: '对所有设备进行管理，精确地获取不同设备的详细信息，并可对其进行管理。' },
    { img: energyShow11, title: '设备巡检', text: '根据需求定时发布设备或特殊区域巡检任务，相关工作人员在规定时间内到现场进行设备或特殊区域巡检，巡检时将巡检结果上传到系统，便于及时发现并整改问题或隐患。' },
    { img: energyShow12, title: '瞬时值', text: '用于统计设备采集值的相关信息，包括变化趋势以及不同时间的采集值等信息，可以有效地获取设备采集情况，以及判断设备采集是否异常等。' },
    { img: energyShow13, title: '应用中心', text: '该模块的主要功能是对各模块进行订阅以及排序，已订阅的模块展示出来，未订阅的模块在平台上隐藏，也可跟不同该模块的重要成都对模块进行拖动排序。' },
    { img: energyShow14, title: '用量查询', text: '根据需求统计不同区域或设备的累计用能情况，可根据需求快捷查询日、月、年等用能数据，也可自定义查询一段时间内容的用能情况，从而实现对不同用能单位的用能监控。' }
  ],
  air: [ 
    { img: airshow1, title: '登录页', text: '系统登录页，通过不同账号控制权限，从而实现不同用户进入系统后展示其所关心或所负责的模块，从而提高用户的工作效率，同时也可极大地避免用户误操作等情况、保证数据的安全可靠性。' },
    { img: airshow2, title: '采集设置', text: '设置采集信息，高效地管理数据采集设备，并可实时查看采集设备的状态信息等。' },
    { img: airshow3, title: '告警统计', text: '统计设备的告警信息，包括数据告警与离线告警，同时根据设备的告警级别进行告警的分类统计。' },
    { img: airshow4, title: '数据看板', text: '统计空调的使用状态、耗能以及告警等信息。' },
    { img: airshow5, title: '组态内容编辑', text: '通过组态可添加场景，直观地展示现场设备实际的运行状态等信息。' }
  ],
  water: [ 
    { img: watershow1, title: '系统导航', text: '通过导航页可进入不同的平台，实现多项目管理。' },
    { img: watershow2, title: '节水看板', text: '根据实际情况以看板的形式统计总用水趋势、人均用水以及节水率统计等信息。' },
    { img: watershow3, title: '报表统计', text: '通过不同形式的报表满足企业在生产过程的数据、报表展示等需求，主要包含了年、月、日报表，同环比报表，用水精细化报表等各种样式的报表，该功能可以帮助企业对数据进行统计分析、分类、按时间和区间进行汇总。' },
    { img: watershow4, title: '报修管理', text: '从移动端进行报修数，在pc端统计所有的报修及维修数据，清楚地展示所有的报修、维修情况的详细数据信息。' },
    { img: watershow5, title: '告警统计', text: '统计设备的告警信息，包括数据告警与离线告警，同时根据设备的告警级别进行告警的分类统计。' },
    { img: watershow6, title: '节水率分析', text: '通过对不同用水单元的用水量与用水定额基准进行对比，统计分析对应的节水量、节水率趋势，可根据不同区域的节水情况，制定相应的用水管控举措。' },
    { img: watershow7, title: '人均用水分析', text: '对人员密集的区域进行人均用水统计，分析人均用水量是否超标，便于对用水分配做进一步的管控等。' },
    { img: watershow8, title: '设备巡检', text: '根据需求定时发布设备或特殊区域巡检任务，相关工作人员在规定时间内到现场进行设备或特殊区域巡检，巡检时将巡检结果上传到系统，便于及时发现并整改问题或隐患。' },
    { img: watershow9, title: '水平衡管理', text: '直观地展示用水单位的用水结构分布情况等信息，并计算各级别水表的水平衡差，通过水平衡差可精确地获取水管漏水的区域。' },
    { img: watershow10, title: '夜间用水分析', text: '针对特定时间段统计水流量，超过某个特定的阈值时，则为用水异常，便于对用水异常区域进行检查是否有漏损等情况。' },
    { img: watershow11, title: '仪表管理', text: '可实时查看水表的状态信息，其中包括正常、掉线、告警、屏蔽等状态信息，从而精确定位异常水表，有助于提高设备维护效率。' }
  ],
  equipment: [ 
    { img: equipmentshow1, title: '登录页', text: '系统登录页，通过不同账号控制权限，从而实现不同用户进入系统后展示其所关心或所负责的模块，从而提高用户的工作效率，同时也可极大地避免用户误操作等情况、保证数据的安全可靠性。' },
    { img: equipmentshow2, title: '数据总览', text: '该模块统计各类工作情况、费用成本及预算等信息，主要包括数据总览、维修统计、保养统计、购置统计、新建统计、报废统计以及证书管理这几个模块的内容。' },
    { img: equipmentshow3, title: '安全管理', text: '安全管理只要针对各设备的大修风险形成评估报告以及各类安全隐患检查形成记录并对已整改的形成整改记录等。' },
    { img: equipmentshow4, title: '保养工作', text: '保养工作主要分为一级保养和二级保养，但只有二级保养工作需要审批形成工作流程，保养工作多为获取保养计划中的保养内容，也可根据实际工作情况临时添加保养工作。' },
    { img: equipmentshow5, title: '出入库记录', text: '统计所有物料的出入库信息，精确定位物料的购买以及用途等信息。' },
    { img: equipmentshow6, title: '待审批', text: '统计所有维修、保养及计划等表单的审批状态等，可从该页面进入对应的表单进行审批等操作，同时统计所有快要到期的各类证书。' },
    { img: equipmentshow7, title: '费用统计分析表', text: '以报表的形式分年度统计各部门各类设备的费用预算以及实际费用等情况，可跳转查看某一类设备的费用明细。' },
    { img: equipmentshow8, title: '立项维修', text: '统计所有的维修立项，并显示其状态，也可添加新的维修立项，通过审批后立项开始执行。' },
    { img: equipmentshow9, title: '立项维修申请单', text: '发起立项时，需要根据需求填入相关信息发起申请之后，审批通过后，改立项生效。' },
    { img: equipmentshow10, title: '资产台账库', text: '统计所有台账基本信息、技术资料、维修保养记录等信息。' },
    { img: equipmentshow11, title: '维保物料库', text: '维保物料库主要功能用于保存设备保养方案，针对不同的设备制定最有效的保养方案，从而尽可能地提高设备的质量与寿命等，同时将每次设备的保养记录在系统存档，做到有迹可循。' },
    { img: equipmentshow12, title: '维修工作', text: '维修工作主要分为大修、常规维修、非计划维修，大修和常规维修大部分获取维修计划中的维修内容形成维修工作流程，也可手动添加，非计划维修则为临时添加的维修内容。' },
    { img: equipmentshow13, title: '维修计划', text: '维修计划可以通过导入或手动添加，展示所有维修计划的审批状态、工作状态、维修预算的信息，计划审批通过后，转入工作装了，形成维修流程待维修状态。' },
    { img: equipmentshow14, title: '证书管理', text: '证书管理用于管理所有的证书，并显示所有证书的使用状态，快到期或已过期的项目均会做相应的提示。' }
  ]
};