import { FloatButton } from 'antd';
import { jump } from '../../utils';

const FixedButton = ({ menus, active }) => {
  return <FloatButton.Group shape="square" style={{ right: 24, fontSize: 20 }}>
    { menus.map(m => <FloatButton
      icon={false}
      key={m.url + 'FloatButton'}
      onClick={() => jump(m.url)}
      description={m.name.replace('/', '').substring(0, 4)}
      type={m.type === active ? 'primary' : 'default'}
    />) }
    <FloatButton.BackTop visibilityHeight={600} />
  </FloatButton.Group>
}

export default FixedButton;